import React, { useState } from "react";
import styles from "./ByzwizVideoModal.module.scss";
import sendIcon from "assets/icons/sendIcon.svg";
import playstoreLinkQR from "assets/images/playStoreQr.png";
import appletoreLinkIcon from "assets/images/appleStoreQr.png";
import playstoreMobileLinkIcon from "assets/icons/playstoreLinkIcon.png";
import applestoreLinkIcon from "assets/icons/applestoreLinkIcon.png";
import closeIcon from "assets/icons/closeIcon.png";
import { useMediaQuery } from "react-responsive";
import ActiveSendIcon from "assets/icons/ActiveSendIcon.svg";
import { ShareApp } from "api/ShareApp";
import toast from "react-hot-toast";
type SendLinkModalProps = {
  open: boolean;
  handleModal: () => void;
};
type responseType = {
  success: boolean;
  message: string;
};
function ByzwizVideoModal({ open, handleModal }: SendLinkModalProps) {
  const [link, setLink] = useState("Get");
  const [inputVal, setInputVal] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });
  const handleSubmit = () => {
    ShareApp({ mobile: inputVal })
      .then(async (res) => {
        const data = (await res?.data) as responseType;
        if (data?.success) {
          toast.success(data?.message, {
            position: "bottom-center",
            duration: 2000,
          });
          setInputVal("");
          handleModal();
        } else {
          toast?.error(data?.message, {
            position: "bottom-center",
            duration: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (!open) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.boxContainer}>
        <div
          className={styles.closeIconContainer}
          onClick={() => handleModal()}
        >
          <img src={closeIcon} className={styles.closeIcon} alt="closeIcon" />
        </div>
        <div className={styles.modalBody}>
          <video className={styles.byzwizVideo} autoPlay controls>
            <source src="https://d36503ntu9mbzu.cloudfront.net/byzwiz_intro_video1.mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}
export default ByzwizVideoModal;
