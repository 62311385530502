import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <HelmetProvider>
    <Helmet>
      <title>ByzWiz</title>
      <meta name="description" content="True Social Commerce" />

      {/* <meta itemprop="name" content="ByzWiz Super Admin" />
      <meta itemprop="description" content="True Social Commerce" />
      <meta itemprop="image" content="https://test.mc.byzwiz.com/metaImage.png" /> */}

      <meta property="og:url" content="https://www.byzwiz.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="ByzWiz" />
      <meta
        property="og:description"
        content="India's First Home-to-Consumer Social-Prise Platform"
      />
      <meta
        property="og:image"
        content="https://www.byzwiz.com/metaImage.png"
      />

      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="ByzWiz" />
      <meta name="twitter:description" content="True Social Commerce" />
      <meta
        name="twitter:image"
        content="https://www.byzwiz.com/metaImage.png"
      />
    </Helmet>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
