import "swiper/css";
import styles from "./NewLandingPage.module.scss";
import HeaderSection from "./HeaderSection";
import BuySection from "./BuySection";
import ConnectSection from "./ConnectSection";
import FooterSection from "./FooterSection";

const NewLandingPage = () => {
  return (
    <div className={styles.mainContainer} id="landing-page">
      <HeaderSection />
      <BuySection />
      <ConnectSection />
      <FooterSection />
    </div>
  );
};

export default NewLandingPage;
