import styles from "./NewLandingPage.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { EffectCreative, Autoplay } from "swiper/modules";

import FoodCardImage from "assets/images/foodCardImage.png";
import ProductCardImage from "assets/images/productCardImage.png";
import VideoCardImage from "assets/images/videoCardImage.png";

const AnimatedCard = () => {
  return (
    <div className={styles.cardContainer}>
      <Swiper
        className={styles.swipper}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectCreative]}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: false,
            translate: ["-120%", 0, -500],
          },
          next: {
            shadow: false,
            translate: ["120%", 0, -500],
          },
        }}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
      >
        <SwiperSlide>
          <img src={FoodCardImage} className={styles.cardImage} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ProductCardImage} className={styles.cardImage} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={VideoCardImage} className={styles.cardImage} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AnimatedCard;
