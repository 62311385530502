import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import AppButton from "atoms/AppButton/AppButton";
// import Logo from "assets/images/byzWizLogoV2.png";
import Logo from "assets/images/byzwizlogoTradeMark.png";
import hbIcon from "assets/icons/hbIcon.png";
import barIcon from "assets/icons/bar.png";
import styles from "./Header.module.scss";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "App";

const navLinks = [
  { id: "1", link: "Sell", to: "sell", offset: 100 },
  { id: "2", link: "Buy", to: "buy", offset: 80 },
  { id: "3", link: "Connect", to: "connect", offset: 80 },
  // { id: "4", link: "Engage", to: "engage", offset: 50 },
];

const mobileNavLinks = [
  { id: "1", link: "Sell", to: "sell", offset: 100 },
  { id: "2", link: "Buy", to: "buy", offset: 50 },
  { id: "3", link: "Connect", to: "connect", offset: 50 },
  // { id: "4", link: "Engage", to: "engage", offset: 50 },
  { id: "5", link: "Privacy Policy", to: "privacy-policy", offset: 50 },
  { id: "6", link: "Terms of Service", to: "services-terms", offset: 50 },
  { id: "7", link: "ByzWiz", to: "2023 ByzWiz", offset: 50 },
];

const tabletPortraitNavLinks = [
  { id: "1", link: "Sell", to: "sell", offset: 100 },
  { id: "2", link: "Buy", to: "buy", offset: 70 },
  { id: "3", link: "Connect", to: "connect", offset: 50 },
  // { id: "4", link: "Engage", to: "engage", offset: 50 },
  // { id: "5", link: "Privacy Policy", to: "privacy-policy", offset: 50 },
  // { id: "6", link: "Terms of Service", to: "services-terms", offset: 50 },
  // { id: "7", link: "ByzWiz", to: "2023 ByzWiz", offset: 50 },
];

const tabletPortraitFooterLinks = [
  { id: "5", link: "Privacy Policy", to: "privacy-policy", offset: 50 },
  { id: "6", link: "Terms of Service", to: "services-terms", offset: 50 },
  { id: "7", link: "ByzWiz", to: "2023 ByzWiz", offset: 50 },
];

const pageLinks = [
  { id: "5", link: "Privacy Policy", to: "privacy-policy", offset: 50 },
  { id: "6", link: "Terms of Service", to: "services-terms", offset: 50 },
  { id: "7", link: "ByzWiz", to: "2023 ByzWiz", offset: 50 },
];

const transition = {
  duration: 1,
  ease: [0.6, 0.01, -0.05, 0.9],
};

type HeaderProps = {
  handleDownload: () => void;
};

const Header = ({ handleDownload }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  const isTabletPortrait = useMediaQuery({
    minWidth: 768,
    maxWidth: 1334,
    orientation: "portrait",
  });

  const [activeLink, setActiveLink] = useState(navLinks[0].link);
  const [openNav, setOpenNav] = useState(false);

  const { isScrolling, setIsScrolling } = useContext(MainContext);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window]);

  if (location.pathname?.includes("/download")) {
    return null;
  }
  return (
    <>
      <motion.div
        className={`${styles.header} ${isScrolling && styles.shadow}`}
        animate={{
          y: 0,
        }}
        initial={{
          y: -70,
        }}
        transition={{
          type: "spring",
          duration: 1,
        }}
      >
        <div className={styles.logoContainer}>
          <img
            src={Logo}
            className={styles.image}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <div className={styles.betaTag}>
            <p className={styles.beta}>Beta</p>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className={styles.dFlex}>
              {(location.pathname?.includes("/privacy-policy") ||
              location.pathname?.includes("/services-terms")
                ? []
                : isTabletPortrait
                  ? tabletPortraitNavLinks
                  : navLinks
              ).map((item, index) => {
                return (
                  <Link
                    to={item.to}
                    key={item.id}
                    spy={true}
                    offset={-item.offset}
                    duration={600}
                    className={styles.item}
                    activeClass={styles.activeItem}
                    // containerId="landing-page"
                  >
                    {item.link}
                  </Link>
                );
              })}
            </div>

            {/* {!isTabletPortrait && (
              <a
                href="https://byb.byzwiz.com"
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <AppButton className={styles.sbtn}>
                  <div className={styles.showFlex}>
                    <img
                      src={hbIcon}
                      className={styles.hbIcon}
                      alt="Home Business Icon"
                    />
                    <p className={styles.btnText}>Start a Home Business</p>
                  </div>
                </AppButton>
              </a>
            )} */}
          </>
        )}

        {isMobile && (
          <div className={styles.menuContainer}>
            {/* <AppButton className={styles.appBtn}> */}
            {/*   <div */}
            {/*     className={styles.showFlex} */}
            {/*     style={{ gap: "10px" }} */}
            {/*     onClick={() => handleDownload()} */}
            {/*   > */}
            {/*     <img */}
            {/*       src={mobileIcon} */}
            {/*       className={styles.playStoreIcon} */}
            {/*       alt="Home Business Icon" */}
            {/*     /> */}
            {/*   </div> */}
            {/* </AppButton> */}
            <div className={styles.navBar} onClick={() => setOpenNav(!openNav)}>
              <img
                src={barIcon}
                className={styles.bar}
                alt="nav-bar"
                onClick={() => setOpenNav(!openNav)}
              />
              {openNav && (
                <div className={styles.navMainContainer}>
                  <div className={styles.navContainer}>
                    {(location.pathname?.includes("/privacy-policy") ||
                    location.pathname?.includes("/services-terms")
                      ? pageLinks
                      : isTabletPortrait
                        ? tabletPortraitFooterLinks
                        : mobileNavLinks
                    ).map((item, index) => {
                      return (
                        <div className={styles.fText} key={index}>
                          {(item?.link === "Privacy Policy" ||
                            item?.link === "Terms of Service") && (
                            <p
                              className={styles.footerLink}
                              style={{
                                color: "#7D7B8A",
                                paddingTop: index === 0 ? "0px" : "12px",
                                fontWeight: index === 0 ? 500 : 400,
                              }}
                              onClick={() => navigate(`/${item?.to}`)}
                            >
                              {item?.link}
                            </p>
                          )}

                          {item.link === "ByzWiz" && (
                            <Link
                              to={item.to}
                              key={item.id}
                              // spy={true}
                              offset={-item.offset}
                              duration={600}
                              className={styles.item}
                              activeClass={styles.activeItem}
                              // containerId="landing-page"
                              onClick={() => setOpenNav(!openNav)}
                            >
                              <p
                                className={styles.footerLink}
                                style={{
                                  color: "#7D7B8A",

                                  paddingBottom:
                                    item.link === "ByzWiz" ? "0px" : "12px",

                                  paddingTop: index === 0 ? "0px" : "12px",
                                  fontWeight: index === 0 ? 500 : 400,
                                }}
                              >
                                &copy; {new Date().getFullYear()} {item.link}
                              </p>
                            </Link>
                          )}
                          {Boolean(
                            item.link !== "ByzWiz" &&
                              item?.link !== "Privacy Policy" &&
                              item?.link !== "Terms of Service",
                          ) && (
                            <Link
                              to={item.to}
                              key={item.id}
                              // spy={true}
                              offset={-item.offset}
                              duration={600}
                              className={styles.item}
                              activeClass={styles.activeItem}
                              // containerId="landing-page"
                              onClick={() => setOpenNav(!openNav)}
                            >
                              <p
                                className={styles.footerLink}
                                style={{
                                  color: "#7D7B8A",

                                  paddingBottom:
                                    item.link === "2023  ByzWiz"
                                      ? "0px"
                                      : "12px",

                                  paddingTop: index === 0 ? "0px" : "12px",
                                  fontWeight: index === 0 ? 500 : 400,
                                }}
                              >
                                {item.link}
                              </p>
                            </Link>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default Header;
