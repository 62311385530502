import { AnimatePresence } from "framer-motion";
import Footer from "organisms/Footer/Footer";
import Header from "organisms/Header/Header";
import SendLinkModal from "organisms/SendLinkModal/SendLinkModal";
import { createContext, useContext, useState } from "react";
import useModal from "utils/hooks/useModal";
import "./App.scss";
import RouterConfig from "./RouterConfig";
import { Toaster } from "react-hot-toast";
import ByzwizVideoModal from "organisms/ByzwizVideoModal/ByzwizVideoModal";

type State = {
  isScrolling: boolean;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultValue: State = {
  isScrolling: false,
  setIsScrolling: () => false,
};
export const MainContext = createContext<State>(defaultValue);

function App() {
  const { handleModal, openModal, openVideoModal, handleVideoModal } =
    useModal();
  const [isScrolling, setIsScrolling] = useState(false);
  return (
    <MainContext.Provider
      value={{
        isScrolling,
        setIsScrolling,
      }}
    >
      <AnimatePresence>
        <Header handleDownload={handleModal} />
        <RouterConfig handleVideoModal={handleVideoModal} />
        <SendLinkModal handleModal={handleModal} open={openModal} />
        <ByzwizVideoModal
          open={openVideoModal}
          handleModal={handleVideoModal}
        />
        <Toaster />
      </AnimatePresence>
    </MainContext.Provider>
  );
}

export default App;
