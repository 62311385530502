/*eslint-disable */
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import LandingImage1 from "assets/images/landingImage1.webp";
import LandingImage2 from "assets/images/landingImage2.webp";
import LandingImage3 from "assets/images/landingImage3.webp";

import LandingFoodImage1 from "assets/images/landingFoodImage1.webp";
import LandingFoodImage2 from "assets/images/landingFoodImage2.webp";

import LandingProductImage1 from "assets/images/landingProductImage1.webp";
import LandingProductImage2 from "assets/images/landingProductImage2.webp";

import LandingVideoImage1 from "assets/images/landingVideoImage1.webp";
import LandingVideoImage2 from "assets/images/landingVideoImage2.webp";

import MobileLandingImage1 from "assets/images/mobileLandingImage1.webp";
import MobileLandingImage2 from "assets/images/mobileLandingImage2.webp";
import MobileLandingImage3 from "assets/images/mobileLandingImage3.webp";

import MobileLandingFoodImage1 from "assets/images/mobileLandingFoodImage1.webp";
import MobileLandingFoodImage2 from "assets/images/mobileLandingFoodImage2.webp";

import MobileLandingProductImage1 from "assets/images/mobileLandingProductImage1.webp";
import MobileLandingProductImage2 from "assets/images/mobileLandingProductImage2.webp";

import MobileLandingVideoImage1 from "assets/images/mobileLandingVideoImage1.webp";
import MobileLandingVideoImage2 from "assets/images/mobileLandingVideoImage2.webp";

import TabLandingImage1 from "assets/images/tabLandingImage1.webp";
import TabLandingImage2 from "assets/images/tabLandingImage2.webp";
import TabLandingImage3 from "assets/images/tabLandingImage3.webp";

import TabLandingFoodImage1 from "assets/images/tabLandingFoodImage1.webp";
import TabLandingFoodImage2 from "assets/images/mobileLandingFoodImage2.webp";

import TabLandingProductImage1 from "assets/images/tabLandingProductImage1.webp";
import TabLandingProductImage2 from "assets/images/tabLandingProductImage2.webp";

import TabLandingVideoImage1 from "assets/images/tabLandingVideoImage1.webp";
import TabLandingVideoImage2 from "assets/images/tabLandingVideoImage2.webp";

import ForwardArrow from "assets/icons/forwardArrow.png";
import HeaderVideoThumbnail from "assets/images/headerVideoThumbnail.png";
import styles from "./NewLandingPage.module.scss";
import { Autoplay } from "swiper/modules";
import useModal from "utils/hooks/useModal";
import ByzwizVideoModal from "organisms/ByzwizVideoModal/ByzwizVideoModal";
import { useEffect, useRef, useState } from "react";
import { useReducedMotion } from "framer-motion";

const collections = {
  Food: [LandingImage1, LandingFoodImage1, LandingFoodImage2],
  Product: [LandingImage2, LandingProductImage1, LandingProductImage2],
  Video: [LandingImage3, LandingVideoImage1, LandingVideoImage2],
};

const mobileCollections = {
  Food: [MobileLandingImage1, MobileLandingFoodImage1, MobileLandingFoodImage2],
  Product: [
    MobileLandingImage2,
    MobileLandingProductImage1,
    MobileLandingProductImage2,
  ],
  Video: [
    MobileLandingImage3,
    MobileLandingVideoImage1,
    MobileLandingVideoImage2,
  ],
};

const tabCollections = {
  Food: [TabLandingImage1, TabLandingFoodImage1, TabLandingFoodImage2],
  Product: [TabLandingImage2, TabLandingProductImage1, TabLandingProductImage2],
  Video: [TabLandingImage3, TabLandingVideoImage1, TabLandingVideoImage2],
};

const HeaderSection = () => {
  const { openVideoModal, handleVideoModal } = useModal();
  const [swipperImage, setSwipperImage] = useState(1);
  const [randomFoodIndex, setRandomFoodIndex] = useState(() => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomValue;
  });
  const [randomProductIndex, setRandomProductIndex] = useState(() => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomValue;
  });
  const [randomVideoIndex, setRandomVideoIndex] = useState(() => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomValue;
  });
  const swiperRef = useRef<any>(null);

  const getRandomInt = (): number => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    if (randomValue === randomFoodIndex) {
      return getRandomInt();
    } else {
      return randomValue;
    }
  };
  const getRandomProductInt = (): number => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    if (randomValue === randomProductIndex) {
      return getRandomProductInt();
    } else {
      return randomValue;
    }
  };

  const getRandomVideoInt = (): number => {
    const min = Math.ceil(0);
    const max = Math.floor(2);
    const randomValue = Math.floor(Math.random() * (max - min + 1)) + min;
    if (randomValue === randomVideoIndex) {
      return getRandomVideoInt();
    } else {
      return randomValue;
    }
  };

  useEffect(() => {
    if (swipperImage === 1) {
      const currentProductIndex = getRandomProductInt();
      if (currentProductIndex !== undefined) {
        setRandomProductIndex(currentProductIndex);
      }
    }
    if (swipperImage === 2) {
      const currentVideoIndex = getRandomVideoInt();
      if (currentVideoIndex !== undefined) {
        setRandomVideoIndex(currentVideoIndex);
      }
    }

    if (swipperImage === 3) {
      const currentFoodIndex = getRandomInt();
      if (currentFoodIndex !== undefined) {
        setRandomFoodIndex(currentFoodIndex);
      }
    }
  }, [swipperImage]);

  return (
    <div className={styles.header} id="sell">
      <Swiper
        ref={swiperRef}
        className={styles.swipper}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        loop={true}
        onRealIndexChange={(swiperCore: any) => {
          if (swiperRef && swiperRef.current) {
            if (swipperImage < 3) {
              setSwipperImage(swipperImage + 1);
            } else {
              setSwipperImage(1);
            }
          }
        }}
        initialSlide={0}
      >
        <SwiperSlide>
          <img
            src={collections["Food"][randomFoodIndex]}
            className={styles.image}
          />
          <img
            src={mobileCollections["Food"][randomFoodIndex]}
            className={styles.mobileImage}
          />
          <img
            src={tabCollections["Food"][randomFoodIndex]}
            className={styles.tabImage}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={collections["Product"][randomProductIndex]}
            className={styles.image}
          />
          <img
            src={mobileCollections["Product"][randomProductIndex]}
            className={styles.mobileImage}
          />
          <img
            src={tabCollections["Product"][randomProductIndex]}
            className={styles.tabImage}
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={collections["Video"][randomVideoIndex]}
            className={styles.image}
          />
          <img
            src={mobileCollections["Video"][randomVideoIndex]}
            className={styles.mobileImage}
          />
          <img
            src={tabCollections["Video"][randomVideoIndex]}
            className={styles.tabImage}
          />
        </SwiperSlide>
      </Swiper>

      <div className={styles.contentContainer}>
        <div className={styles.contectBox}>
          <p className={styles.title}>
            Sell on <span>ByzWiz</span>
          </p>
          <p className={styles.subHeading}>
            Start your dream home businesses for free!
          </p>
          <p className={styles.subTitle}>
            Monetize your innate skills and talents across 3 verticals
          </p>
          <div className={styles.verticalContainer}>
            <div>
              <p className={styles.vertical}>Food</p>
              <div className={styles.progressContainer}>
                {swipperImage === 1 && (
                  <div className={styles.foodProgress}></div>
                )}
                {swipperImage > 1 && <div className={styles.foodBorder}></div>}
              </div>
            </div>
            <div className={styles.separator} />
            <div>
              <p className={styles.vertical}>Products</p>
              <div className={styles.progressContainer}>
                {swipperImage === 2 && (
                  <div className={styles.productProgress}></div>
                )}
                {swipperImage > 2 && (
                  <div className={styles.productBorder}></div>
                )}
              </div>
            </div>
            <div className={styles.separator} />
            <div>
              <p className={styles.vertical}>Video</p>
              <div className={styles.progressContainer}>
                {swipperImage === 3 && (
                  <div className={styles.videoProgress}></div>
                )}
              </div>
            </div>
          </div>
          <a href="https://byb.byzwiz.com" target={"_blank"}>
            <button className={styles.btn}>
              <p>Know more</p>
              {/* <p>Start your Home Business</p> */}
              <img src={ForwardArrow} className={styles.forwardArrow} />
            </button>
          </a>
          <div className={styles.operationalContainer}>
            <p className={styles.operational}>
              Operational in Maharashtra State.
            </p>
          </div>
        </div>
      </div>
      <img
        src={HeaderVideoThumbnail}
        className={styles.videoThumbnail}
        onClick={() => {
          handleVideoModal();
          if (swiperRef.current) {
            swiperRef.current.swiper.autoplay.stop();
          }
        }}
      />
      <ByzwizVideoModal
        open={openVideoModal}
        handleModal={() => {
          handleVideoModal();
          if (swiperRef.current) {
            swiperRef.current.swiper.autoplay.start();
          }
        }}
      />
    </div>
  );
};

export default HeaderSection;
