import React, { useEffect } from "react";

function DownloadPage() {
  useEffect(() => {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.byzwiz",
    );
  }, []);
  return <div></div>;
}

export default DownloadPage;
