import { useState, useEffect } from "react";

export default function useModal() {
  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const [openVideoModal, setOpenVideoModal] = useState(false);
  const handleVideoModal = () => {
    setOpenVideoModal(!openVideoModal);
  };

  return { openModal, handleModal, openVideoModal, handleVideoModal };
}
