import { useMediaQuery } from "react-responsive";
import styles from "./TermsOfServices.module.scss";
import { useState, useEffect } from "react";

const TermsOfServices = () => {
  const termsOfServices = [
    {
      title: "Preamble",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            This document, labelled “Terms of Service” along with included
            references to any other documents, describes and explains the terms
            and conditions on which the ByzWiz Platform (Hereinafter referred to
            as ByzWiz, The Platform, The Website, The App, We, Us, Our/s, or a
            reference deemed appropriate within context) may provide services to
            the users of the ByzWiz platform as listed on the ByzWiz websites /
            Mobile and Web applications and it’s linked pages.
          </p>
          <p className={styles.info}>
            This document constitutes an agreement between the ‘USERS’ of the
            ByzWiz platform AND the entity owners of the ByzWiz Platform, the
            ByzWiz website with the web address www.byzwiz.com, The ByzWiz
            mobile and web applications and all other entities / websites /
            applications associated with the ByzWiz Platform, whether personally
            or on behalf of an entity known as ByzWiz and the promoting company
            known as ByzWIz Infratech Private Limited, hereinafter referred to
            as “we,” “us” or “our/s”, concerning access to and use of ‘The
            ByzWiz Platform’ as well as any other media form, media channel,
            mobile website or mobile application related, linked, or otherwise
            connected thereto (collectively known as , EITHER “The ByzWiz
            Platform” OR “The Platform”).
          </p>
          <p className={styles.info}>
            ‘USER/S’, in general, refers to anyone who may use the ByzWiz
            platform, as laid out below: Home Entrepreneurs and their Customers,
            also referred to as Sellers and Buyers. Visitors, implying any
            Person (Identifiable via the sign-up process or anonymous) browsing
            / accessing / using the services provided by the ByzWiz Platform.
            Any person who has signed up to use the ByzWiz platform is referred
            to as a ‘User’ or a ‘Registered User’. Users may also be referred to
            as ‘You’ / ‘Your/s’.
          </p>
          <p className={styles.info}>
            It must be clearly understood that by starting any home businesses
            on the ByzWiz Platform OR by buying / ordering / accessing / using
            any of our Products or Services, you (the user of the ByzWiz
            Platform) agree to be bound by these terms of service laid out in
            this document.
          </p>
          <p className={styles.info}>
            Supplemental terms and conditions or documents that may be
            changed/altered/amended/rectified and posted on the platform, as and
            when published, are to be read and understood before you proceed to
            use our services. The ByzWiz Platform and the owners of the entity,
            ByzWiz Infratech Private Limited, reserve the right, at their sole
            discretion, to make changes/alter/amend/rectify these terms and
            conditions at any time and for any reason whatsoever and you, the
            user of the ByzWiz Platform, agree to be bound by those
            changes/alteration/amendments /rectifications. You are responsible
            for the periodical review of this document and other related
            documents and stay informed about the updates. Accessing of the
            ByzWiz Platform and usage of any services by you will be deemed as
            your agreement and affirmation of these terms and conditions.
          </p>
          <p className={styles.info}>
            The ByzWiz platform is intended for users who are 18 years old and
            above. However, persons between the ages of 13 to 18 are permitted
            to register on the ByzWiz Platform and use the platform with consent
            and under the supervision and legal responsibility of at least one
            of their Parents. It is the supervising and legally responsible
            parents’ responsibility to read these terms of service prior to
            their ward using the platform. By signing up to and using the ByzWiz
            platform, parents of persons between the ages of 13 to 18 assume
            full responsibility and legal liability (if any) while their wards
            are using the ByzWiz Platform.
          </p>
        </div>
      ),
    },
    {
      title: "About Us",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            The ByzWiz Platform is built and maintained by ByzWiz Infratech
            Private Ltd., (Hereinafter referred to as ‘The Company’) with the
            following purposes:
          </p>
          <p className={styles.info}>
            To provide the best possible features and tools to enable Home
            Entrepreneurs to build, manage and conduct multiple Home Businesses
            online, free of cost across three (3) verticals, namely Food,
            Products and Video. To ensure that the said Home Entrepreneurs can
            provide premium exploration, purchasing, and social engagement
            experiences to their Customers / Buyers and other visitors on the
            platform. <br /> To implement and encourage ‘True Social Commerce’.
          </p>

          <p className={styles.info}>
            ByzWiz is created for the economic upliftment of people by providing
            business structures, ideas, strategies, techniques, products, and
            services on the ByzWiz platform along with integrated Social
            Networking, communication tools and several other features to
            facilitate Home Entrepreneurs to conduct multiple home businesses on
            the ByzWiz platform, free of cost. Our users can choose to start
            their home businesses on the ByzWiz platform and sell their products
            and services to their customers. Business success is ensured by
            utilizing the provided digital infrastructure along with sincerity
            and diligence on part of the Home Entrepreneurs who bring their own
            unique ideas, knowledge, skills, techniques, experience, expertise,
            time, motivation and marketing and propagation efforts.
          </p>
          <p className={styles.info}>
            ByzWiz Infratech Private Limited has built, and maintains and
            operates the ByzWiz platform, including the websites
            https://www.byzwiz.com and https://byb.byzwiz.com, The ByzWiz mobile
            / Tablet / web browser applications and any other pages / programs /
            applications within the folds of the ByzWiz platform. The safety,
            quality and reliability of the Products and Services sold by the
            Home Entrepreneurs on the ByzWiz platform are the responsibility of
            the said Home Entrepreneurs. The decision to order / purchase the
            Products and Services offered for sale by the Home Entrepreneurs on
            the ByzWiz Platform are made by the customers / buyers of the said
            Home Entrepreneurs, and therefore the responsibility of such
            purchase decisions lie with the customers / buyers of the Home
            Entrepreneurs. The company, the ByzWiz platform or any associated
            entities do not accept any liabilities on behalf of the Home
            Entrepreneurs or their Customers / Buyers. ByzWiz is only an
            enabler, aggregator and a digital interface provider that enables
            and makes possible such business ventures and transactions. The
            ByzWiz platform, from time to time, through documents such as this
            one, will state guidelines and norms that are to be strictly adhered
            to by both Home Entrepreneurs and their Customers / Buyers. It is
            expressly understood that by using the ByzWiz Platform, all users
            are bound by the terms and conditions outlined in this document.
          </p>
        </div>
      ),
    },
    {
      title: "Contract",
      info: (
        <div className={styles.contentMainBox}>
          <div>
            <p className={styles.subTitle}>Contract Between Us and You</p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                By accessing / viewing / signing up for the ByzWiz Platform or
                by visiting any of the ByzWiz websites, or by downloading,
                installing, and signing up for the ByzWiz mobile or tablet
                applications, you represent and warrant that:
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    That all information provided for registration submitted by
                    you is true, accurate, current, and complete. Further, that
                    you will maintain the accuracy of information provided to us
                    and promptly update such registration information as and
                    when changes to this information occur.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    That you are not under the age of 18; and you are not a
                    minor in the jurisdiction in which you reside, or if a
                    minor, you have received parental consent and permission to
                    use the ByzWiz Platform and website. That you have the legal
                    capacity, and you agree along with your parents, if
                    necessary, to comply with these Terms of Service.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    That you will not access the ByzWiz Platform through
                    automated or non-human means, whether through a bot, script,
                    or otherwise; and that you will not use the platform for any
                    illegal or unauthorized purposes; Further, that your use of
                    the ByzWIz platform will not violate any applicable Indian
                    law or regulation.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse all current or future use
                    of the platform (or any portion thereof).
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <div>
            <p className={styles.subTitle}>User Registration</p>
            <p className={styles.info}>
              You are required to register on the ByzWiz platform / website with
              a Username (Email or Mobile Number) and a Password. It is your
              responsibility to keep your password confidential and you are the
              only one responsible for the use of your account on ByzWiz. We,
              and our associates, agents and staff reserve the right to remove,
              reclaim, or change a username as per our convenience if we
              determine at our sole discretion, that such username is
              inappropriate, obscene, or otherwise objectionable. If you are
              signing up as a Home Entrepreneur on the ByzWIz Platform, you are
              required to share KYC information as is necessary for the sign-up
              process. Please ensure you maintain the accuracy of the
              information you provide. It is your responsibility to update the
              KYC information if there are changes to this information. You
              could be held liable for legal action if it is found that you have
              purposefully submitted wrong / inaccurate KYC information.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>
              Terms for Buyers - Use of the platform and Placement of Orders
            </p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                As a user of the ByzWiz platform, after signing up, you may
                freely browse the ByzWIz mobile application, tablet application
                or the ByzWiz web application to peruse Home Businesses and
                their offerings in all three verticals, i.e., Food, Products and
                Video. You are free to place your orders for the offerings from
                any of the Home Businesses that you may wish to engage with. The
                ByzWIz platform facilitates the ordering process by providing
                access to seamlessly integrated payments, logistics and other
                enabling services so that the orders that you place can be
                fulfilled within reasonable timeframes
              </p>
              <p className={styles.info}>
                When an order is placed by you, we will send you an online
                notification confirming that we have received your order. The
                placement of the order does not imply confirmation / acceptance
                of the order. The received order needs to be confirmed /
                accepted by the Home Businesses from whom you are purchasing the
                goods or services as per their convenience. We at ByzWiz wish to
                deliver the best buying experience for our users, and to that
                end, we ensure and maintain strict best practices guidelines for
                our Home Entrepreneurs so that all transactions are smoothly
                completed and executed flawlessly, to your utmost satisfaction.
              </p>
              <p className={styles.info}>
                When you place an order with us through the ByzWIz platform, the
                order we receive is only an offer made by you to us indicating
                to us that you would like to purchase the product or services
                provided by Home Businesses listed and visible on the ByzWIz
                Platform. The contract will be only applicable when you receive
                the Products and/or Services (as applicable). If you have
                ordered for Item/s listed under the Food or Products Verticals,
                the contract will only relate to the items ordered and received
                by you. If you have ordered for Video based services from Home
                Businesses listed under the Video vertical, the contract will be
                governed by the terms and conditions applicable for the Video
                services provided under the Video Vertical as outlined within
                this document. We request you to please read these terms and
                conditions before placing any orders on the ByzWiz platform. By
                ordering the products and/or services on offer through the Home
                Businesses listed under the 3 verticals on the ByzWiz platform,
                you accept and are bound by all terms and conditions as outlined
                within this document.
              </p>
            </div>
          </div>

          <div className={styles.infoBox}>
            <div>
              <p className={styles.subTitle}>
                Terms for Home Entrepreneurs – Responsibilities for creation /
                management of Home Businesses
              </p>
              <p className={styles.info}>
                The BYB area of the ByzWiz platform, found on the web address
                <a
                  href="https://byb.byzwiz.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className={styles.linkText}>
                    {" "}
                    https://byb.byzwiz.com{" "}
                  </span>
                </a>
                is designed to allow Home Entrepreneurs to build, manage and
                conduct their Home Businesses on the ByzWiz platform. The
                following guidelines are to be adhered to strictly when you
                conduct your Home Businesses on the platform:
              </p>
            </div>
            <div>
              <p className={styles.subSubTitle}>
                For Home Businesses built and operated under the Food Vertical:
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You are responsible for the quality of the food you prepare
                    / procure and sell from your Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    If you are planning to sell fresh fruits and vegetables,
                    either grown by you and/or otherwise procured and cleaned,
                    cut, weighed, packed or in any other manner, processed with
                    some value addition, It is your responsibility to make sure
                    that the fresh fruits and vegetables are sold within a safe
                    timeframe that ensures that these are fresh and safe for
                    consumption.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You guarantee the use of the best and freshest ingredients
                    for your food preparations.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You promise to maintain great kitchen hygiene.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will strictly ensure that your orders are ready for
                    pick-up on time.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will use good quality packaging materials and suitable
                    packing processes to avoid transit damages.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will not sell anything illegal from your Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept all penalties or other necessary
                    disciplinary actions as deemed fit by the platform which may
                    be imposed upon you in case of failure to fulfil your orders
                    or your obligations.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will strive to make your customers happy. You want your
                    home business to grow.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept any and all decisions taken by the
                    platform to resolve customer dis-satisfaction issues,
                    complaints or feedback, including but not limited to
                    returns, refunds, penalties, warnings, temporary or
                    permanent suspension of business activities, and/or legal
                    action the under applicable circumstances, or as legally
                    appropriate and in accordance with the terms and conditions
                    laid out in this document.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <p className={styles.subSubTitle}>
                For Home Businesses built and operated under the Products
                Vertical:
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You are responsible for the quality of products you create /
                    sell from your Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will strictly ensure that your orders are ready for
                    pick-up on time.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will use good quality packaging materials and suitable
                    packing processes to avoid transit damages.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will not sell anything illegal from your Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept all penalties or other necessary
                    disciplinary actions as deemed fit by the platform which may
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will strive to make your customers happy. You want your
                    home business to grow.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept any and all decisions taken by the
                    platform to resolve customer dis- satisfaction issues,
                    complaints or feedback, including but not limited to
                    returns, refunds, penalties, warnings, temporary or
                    permanent suspension of business activities, and/or legal
                    action the under applicable circumstances, or as legally
                    appropriate and in accordance with the terms and conditions
                    laid out in this document.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <p className={styles.subSubTitle}>
                For Home Businesses built and operated under the Video Vertical:
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Pre-Recorded mode of operation, you will only upload
                    original content to your Video Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Pre-Recorded mode of operation, you will only upload
                    family friendly content to your Video Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Pre-Recorded mode of operation, you will strictly ensure
                    that you upload high quality videos.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Live Video mode of operation, you will strictly ensure
                    that your live video sessions start on time.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Live Video mode of operation, you will do your best to
                    ensure your customers are delighted with your sessions.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    For Live Video mode of operation, you will make sure that
                    your video operations are failure proof.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will not engage in any illegal activities through your
                    video Home Business.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept all penalties or other necessary
                    disciplinary actions as deemed fit by the platform which may
                    be imposed upon you in case of failure to fulfil your orders
                    or your obligations.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You will strive to make your customers happy. You want your
                    home business to grow.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    You agree to accept any and all decisions taken by the
                    platform to resolve customer dis-satisfaction issues,
                    complaints or feedback, including but not limited to
                    returns, refunds, penalties, warnings, temporary / permanent
                    suspension of business activities, and/or legal action the
                    under applicable circumstances, or as legally appropriate
                    and in accordance with the terms and conditions laid out in
                    this document.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <p className={styles.subSubTitle}>
                General Terms for all Users of the ByzWiz Platform
              </p>
              <p className={styles.info}>
                As a User of the ByzWiz platform, you agree not to:
              </p>
              <div className={{ marginTop: "10px" }}>
                <ol className={styles.list}>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Retrieve data or other content from the platform to create
                      or compile, directly or indirectly, a collection,
                      compilation, database, or directory without written
                      permission from us.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Make any unauthorized/illegal use of the platform,
                      including collecting usernames and/or email addresses of
                      users by electronic or other means for the purpose of
                      sending unsolicited email, or creating user accounts by
                      automated means or under false pretenses.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use a buying agent or purchasing agent to make purchases
                      on the platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use the platform to advertise or offer to sell goods and
                      services outside the scope of the platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Circumvent, disable, or otherwise interfere with
                      security-related features of the platform, including
                      features that prevent or restrict the use or copying of
                      any content or enforce limitations on the use of the
                      platform and/or the Content contained therein.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Engage in unauthorized framing of or linking to the
                      platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Trick, defraud, or mislead us and other users, especially
                      in any attempt to learn sensitive account information such
                      as user passwords, etc.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Make improper use of our support services or submit false
                      reports of abuse or misconduct.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      You will strive to make your customers happy. You want
                      your home business to grow.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Falsify or misrepresent ratings or feedback wherever
                      applicable with malicious intent.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use inappropriate / foul language while writing comments,
                      messages, complaints, feedback, or while seeking support
                      wherever these may be applicable on the platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Engage in any automated use of the system, such as using
                      scripts to send comments or messages, or using any data
                      mining, robots, or similar data gathering and extraction
                      tools.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Attempt to impersonate another user or person or use the
                      username of another user or attempt to log in to another
                      user’s account.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Sell or otherwise transfer your profile.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use any information obtained from the platform to harass,
                      abuse, or harm another person.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use the platform as part of an effort to compete with us
                      or otherwise use the platform and/or the content found on
                      the platform for any unauthorized revenue-generating
                      endeavor or commercial enterprise.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Decipher, decompile, disassemble, or reverse engineer any
                      of the software comprising or in any way making up a part
                      of the ByzWiz platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Attempt to bypass any measures of the platform designed to
                      prevent or restrict access to the Site, or any portion of
                      the Site.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Harass, annoy, intimidate, or threaten any of our
                      employees or agents engaged in providing services on any
                      portion of the platform to you.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Delete the copyright or other proprietary rights notice
                      from any Content.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Copy or adapt the platforms software, including but not
                      limited to Flash, PHP, HTML, JavaScript, or other code.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Upload or transmit (or attempt to upload or to transmit)
                      viruses, Trojan horses, or other material, including
                      excessive use of capital letters and spamming (continuous
                      posting of repetitive text), that interferes with any
                      party’s uninterrupted use and enjoyment of the platform or
                      modifies, impairs, disrupts, alters, or interferes with
                      the use, features, functions, operation, or maintenance of
                      the platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Upload or transmit (or attempt to upload or to transmit)
                      any material that acts as a passive or active information
                      collection or transmission mechanism, including without
                      limitation, clear graphics interchange formats (“gifs”),
                      1×1 pixels, web bugs, cookies, or other similar devices
                      (sometimes referred to as “spyware” or “passive collection
                      mechanisms” or “PCMs”).
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use, launch, develop, or distribute any automated system,
                      including without limitation, any spider, robot, cheat
                      utility, scraper, or offline reader that accesses the
                      platform, or using or launching any unauthorized script or
                      other software, except as may be the result of standard
                      search engine or Internet browser usage.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Disparage, tarnish, or otherwise harm, in our opinion, us
                      and/or the platform.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Use the platform in a manner inconsistent with any
                      applicable Indian laws or regulations.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Logistics",
      info: (
        <div>
          <p className={styles.subTitle}>
            Delivery of Ordered Food Items and Products
          </p>
          <div className={styles.infoBox}>
            <p className={styles.info}>
              The ByzWiz platform is built with the intention that Home
              Entrepreneurs have access to all possible features and services
              that they may need to run their home businesses successfully. An
              important aspect of this thought process is for us to provide
              logistics services to our home entrepreneurs. ByzWiz, through its
              technology, provides our home entrepreneurs access to both
              Hyperlocal as well as PAN India Courier delivery services to
              ensure cost effective and timely deliveries of Fresh Food Items,
              Packaged Food Items, and Products. These delivery structures are
              built such that the services are provided by tech enabled third
              party service providers.
            </p>
            <p className={styles.info}>
              When you place an order with any of the home businesses listed
              under the Food or Products verticals, your order / package will be
              delivered / supplied / fulfilled /made available for receipt by
              you (as applicable) within a reasonable period indicated to you at
              the time of ordering. This time frame is determined by several
              factors, which includes a summation of the time it takes for the
              home entrepreneur to prepare and package your order, the time
              taken by the designated delivery valet to arrive at the location
              of the home entrepreneur to pick up the package, and for
              Hyperlocal deliveries, the time taken for the delivery valet to
              reach your location. In case of Pan India Courier deliveries, post
              pick up transit times required for intercity transportation are
              also added.
            </p>
            <p className={styles.info}>
              Though we have built a robust technological structure to enable
              such services for the benefit of our home entrepreneurs and their
              buyers / customers, and it is our constant and resolute intent to
              ensure that all logistics systems result in precise and on-time
              deliveries, ByzWiz does not offer any guarantees that the
              deliveries will always be achieved on time, or achieved at all, as
              intended. There are plenty of failure cases that may occur, which
              may be beyond the control of the ByzWiz platform. As such, it is
              always going to be our priority to improve our systems to reduce
              to near zero, the incidents of delivery failure and to
              consistently increase the probability of successful deliveries.
              The ByzWiz platform does not warrant/guarantee the success of
              deliveries, and as such cannot be held legally liable for
              incidental failures with respect to delivery / logistics of
              ordered food items or products under any circumstances. By placing
              orders on the ByzWiz platform, you agree to not hold the ByzWiz
              platform responsible for delivery / logistics failures that may
              occur from time to time, and you agree to not initiate any legal
              proceedings against the ByzWiz platform in a court of law, under
              the Indian legal system.
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Quality",
      info: (
        <div>
          <p className={styles.subTitle}>Quality of Products and Services</p>
          <div className={styles.infoBox}>
            <p className={styles.info}>
              The maintenance of quality of the products and services available
              for purchase from any home businesses registered under the three
              verticals on the ByzWiz Platform, namely, Food, Products and Video
              will be the sole responsibility of the Home Entrepreneurs to whom
              the home businesses belong. The ByzWiz Platform is only a
              facilitator / enabler / aggregator of home entrepreneurs and their
              home businesses and cannot directly control the quality of the
              products and services available / on offer on the ByzWiz platform.
              Guidelines for the Home Entrepreneurs for maintaining the quality
              of their products and services for sale on ByzWiz are outlined in
              this document. Adherence to the said guidelines is absolutely
              necessary without any exceptions. Failure to adhere to the quality
              guidelines will result in temporary or permanent suspension of
              home businesses, monetary penalties, closure of accounts of the
              home entrepreneurs, and/or appropriate legal action.
            </p>
            <p className={styles.info}>
              The Home Entrepreneurs are solely responsible for the Products and
              Services they sell on the ByzWiz platform, with respect to their
              quality, conformity with descriptions, displayed pictures and / or
              videos, declared features, attributes, ingredients, quantities and
              weights, dimensions and sizes and all other pertinent and
              significant characteristics listed and displayed on their home
              businesses on the ByzWiz platform. Home Entrepreneurs are solely
              responsible to ensure correct / appropriate and sufficient
              packaging, and maintaining satisfactory quality of their products
              and services, such that the said products and services be
              reasonably fit for use, consumption experience and satisfy all
              other purposes for which the products and services of such kind
              are commonly purchased and supplied. All other warranties,
              conditions or terms are excluded in so far as permitted by law.
            </p>
            <p className={styles.info}>
              We advise the Buyers / Customers of the home entrepreneurs and
              their home businesses to judiciously examine / assess / evaluate
              the products and services on offer before placing an order. Buyers
              / Customers are solely responsible for their decisions to purchase
              / economically engage the home businesses. By placing orders on
              the ByzWiz platform, you agree to not hold the ByzWiz platform
              responsible for any obligations or liabilities with respect to the
              quality of the products and services purchased, and you agree to
              not initiate any legal proceedings against the ByzWiz platform in
              a court of law, under the Indian legal system.
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Order Cancellation",
      info: (
        <div>
          <p className={styles.subTitle}></p>
          <p className={styles.info}>
            At this point in time, the ByzWiz platform does not, under any
            circumstances, allow for cancellation of orders placed under all
            three verticals of the platform, namely, Food, Products and Video.
            We urge the Buyers / Customers of the listed home businesses on the
            ByzWiz platform to arrive at their purchase decisions after thorough
            and judicious consideration. We may, in the future, allow for
            cancellation of certain types of orders depending on the prevalent
            situation and circumstances at that point in time. We will take
            appropriate decisions in this regard and update these terms of
            service accordingly, at our sole discretion.
          </p>
        </div>
      ),
    },
    {
      title: "Return and Replacements",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            The ByzWiz platform does not, under any circumstances, allow for any
            returns / replacement of items purchased under the Food Vertical of
            the ByzWiz Platform. This is non-negotiable and absolute. We urge
            the Buyers / Customers of the listed home businesses under the Food
            vertical of the ByzWiz platform to arrive at their purchase
            decisions after thorough and judicious consideration. No requests
            pertaining to this will be entertained through any of our customer
            support mechanisms available to you.
          </p>
          <p className={styles.info}>
            Returns / replacement of services purchased under the Video vertical
            is physically not possible. Hence the question of Returns /
            replacement does not arise in this situation.
          </p>
          <div>
            <p className={styles.info}>
              ByzWiz allows returns / replacement of items purchased under the
              Products vertical of the ByzWiz platform under certain specific
              circumstances only. These are listed below:
            </p>
            <ol className={styles.list}>
              <li className={styles.listItem}>
                <p className={styles.info}>
                  If item/s received by the Buyer / Customer are damaged in
                  transit due to poor packaging.
                </p>
              </li>
              <li className={styles.listItem}>
                <p className={styles.info}>
                  If item/s received by the Buyer / Customer deviate from the
                  item/s for which the order was placed in any manner, including
                  item characteristics and quantity.
                </p>
              </li>
            </ol>
          </div>
          <p className={styles.info}>
            Sufficient evidence will need to be provided by the Buyer / Customer
            to the ByzWiz platform AND to the home business to establish the
            damage and/or deviation, to be eligible for return of the delivered
            item/s as stated above. The evidence may be provided in the form of
            pictures, videos, or any other format/mechanism that ByzWiz requires
            and facilitates to adequately demonstrates the damage or deviation
            pertaining to the received item/s, within three (3) days of
            receiving the ordered item/s. The ByzWiz platform in its sole
            discretion and authority will determine the legitimacy of the
            request for return / replacement of the Item/s in question. If the
            request for a return / replacement is found legitimate, ByzWiz will
            direct the Home Entrepreneur to replace the Product / item. The full
            cost of all applicable expenses incurred while doing so shall be
            borne by the Home Entrepreneur. ByzWiz cannot be held liable for any
            expenses or liabilities in this regard. Both the Home Entrepreneur
            and their Buyers / Customers agree to not hold the ByzWiz platform
            responsible for any obligations or liabilities with respect to the
            return / replacement of items found eligible for return /
            replacement by ByzWiz, and you agree to not initiate any legal
            proceedings against the ByzWiz platform in a court of law, under the
            Indian legal system.
          </p>
        </div>
      ),
    },
    {
      title: "Refunds",
      info: (
        <div className={styles.infoBox}>
          <div>
            <p className={styles.subTitle}>Refunds-Food Vertical</p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                Returns / replacement of services purchased under the Video
                vertical is physically not possible. Hence the question of
                Returns / replacement does not arise in this situation.
              </p>
              <div>
                <p className={styles.info}>
                  ByzWiz allows returns / replacement of items purchased under
                  the Products vertical of the ByzWiz platform under certain
                  specific circumstances only. These are listed below:
                </p>
                <ol className={styles.list}>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      When the ordered Food item is unfit for consumption due to
                      bad quality or spoiling in any manner.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      When the received Food item differs from the ordered food
                      item, either by way of preparation or by weight or
                      quantity, or any other deviation as may be realized by the
                      Buyer / Customer.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      If the received food item has spilled out due to poor
                      packaging.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      If the ordered food item is not delivered at all.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Any other cases which may arise from time to time that
                      cannot be foreseen at this stage but are brought to the
                      attention of ByzWIz via a refund request.
                    </p>
                  </li>
                </ol>
              </div>
              <p className={styles.info}>
                Refund requests to be raised with ByzWiz within One (1) day of
                the relevant order.
              </p>
            </div>
          </div>
          <div>
            <p className={styles.subTitle}>Refunds-Products Vertical</p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                Requests for refunds are processed by the ByzWiz platform for
                items purchased from the Products Vertical under the following
                circumstances:
              </p>
              <div>
                <ol className={styles.list}>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      When the received item differs from the ordered item,
                      either by deviation in appearance, features as described
                      in the product listing, difference in weight or quantity,
                      or any other deviation as may be realized by the Buyer /
                      Customer.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      When the received Food item differs from the ordered food
                      item, either by way of preparation or by weight or
                      quantity, or any other deviation as may be realized by the
                      Buyer / Customer.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      If the received item is damaged due to poor packaging.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      If the ordered food item is not delivered at all.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Any other cases which may arise from time to time that
                      cannot be foreseen at this stage but are brought to the
                      attention of ByzWIz via a refund request.
                    </p>
                  </li>
                </ol>
              </div>
              <p className={styles.info}>
                Refund requests to be raised with ByzWiz within Three (3) days
                of the relevant order.
              </p>
            </div>
          </div>
          <div>
            <p className={styles.subTitle}>Refunds-Video Vertical</p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                Requests for refunds are processed by the ByzWiz platform for
                the Video Vertical under the following circumstances:
              </p>
              <div>
                <ol className={styles.list}>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Only Live Video sessions are eligible for refunds.
                      Membership or Album access purchases are not considered
                      eligible for any refund requests currently.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Customers / Buyers may request a refund if a Live Video
                      session is not executed correctly in any manner, which may
                      include a missed session, delayed start, interruptions
                      which may result in dissatisfaction, or content not
                      delivered as promised by the home entrepreneur.
                    </p>
                  </li>
                  <li className={styles.listItem}>
                    <p className={styles.info}>
                      Any other cases which may arise from time to time that
                      cannot be foreseen at this stage but are brought to the
                      attention of ByzWIz via a refund request.
                    </p>
                  </li>
                </ol>
              </div>
              <p className={styles.info}>
                Refund requests to be raised with ByzWiz within One (1) day of
                the designated time of the relevant Live Video session.
              </p>
              <p className={styles.info}>
                Sufficient evidence will need to be provided by the Buyer /
                Customer to the ByzWiz platform AND to the home business to
                establish the reason for request for a refund. The evidence may
                be provided in the form of pictures, videos, or any other
                format/mechanism that ByzWiz requires and facilitates while
                requesting for a refund within the stipulated time frame
                mentioned above for each of the 3 verticals. The ByzWiz platform
                in its sole discretion and authority will determine the
                legitimacy of the request for refunds.
              </p>
              <p className={styles.info}>
                If the request for a refund is found legitimate, ByzWiz will,
                within seven (7) working days from the date of approval of
                refund, process and execute the refund.
              </p>
              <p className={styles.info}>
                ByzWiz cannot be held liable for any incidental expenses or
                liabilities in this regard exceeding the amount in Rupees
                mentioned on the invoice. Both the Home Entrepreneur and their
                Buyers / Customers wholly agree to accept without contest or
                opposition, the decision arrived at by ByzWiz to either execute
                or not execute the refund, and to not hold the ByzWiz platform
                responsible for any obligations or liabilities with respect to
                the process of refunds of the items found eligible for a refund
                by ByzWiz, and you agree to not initiate any legal proceedings
                against the ByzWiz platform in a court of law, under the Indian
                legal system.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Payouts",
      info: (
        <div>
          <div className={styles.infoBox}>
            <p className={styles.info}>
              Whenever you (the Home Entrepreneur) sell anything on ByzWiz, the
              platform generates and submits invoices for clearance on your
              behalf. This is done automatically once the platform receives
              confirmation of delivery of a Food or a Product order, or
              successful completion of Live Video Sessions.
            </p>
            <p className={styles.info}>
              ByzWiz collects payments from your customers on your behalf. This
              is reflected in your Ledger. All due payments (against Cleared
              Invoices) are displayed as your Credit Balance and can be
              withdrawn into your Registered Bank Account by clicking the
              Withdraw button on the Dashboard.
            </p>
            <p className={styles.info}>
              Withdrawal of funds follows a Two Factor Authentication check to
              enable secure transfers. A small withdrawal fee may be levied by
              the payment gateway, which, if applicable, will be borne by you,
              the Home Entrepreneur.
              <span className={styles.linkText}> Click here</span> to know more
              about the withdrawal fee structure.
            </p>
            <p className={styles.info}>
              Payouts are structured according to the Verticals as described
              below:
            </p>
            <div>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    All Food related payments follow a ‘T+2 days’ pay-out time
                    frame. This means that due payments under the Food Vertical
                    will reflect in your Credit Balance 2 days after successful
                    delivery.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    All Products related payments follow a ‘T+7 days’ pay
                    out-time frame. This means that due payments under the
                    Products Vertical will reflect in your Credit Balance 7 days
                    after successful delivery.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    All ‘Single Session’ Live Video (One-on-One & Groups)
                    related payments follow a ‘T+2 days’ pay-out time frame.
                    This means that due payments under the Video Vertical for
                    all Single Session Listings (One-on-One & Groups) will
                    reflect in your Credit Balance 2 days after successful
                    session completion.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    All Live Video (One-on-One and Groups) ‘Classes and Courses’
                    Listings shall follow a pay-out structure where your Ledger
                    will be credited by the cost component of the previous
                    session only when previous and current sessions have been
                    successfully completed. For Example: If there are 3 sessions
                    defined within a Course Listing, the cost component of
                    session 1 will be credited to the ledger only when session 2
                    is successfully completed. The cost component of session 2
                    will be credited to the ledger only when session 3 is
                    successfully completed the cost component of session 3 will
                    follow the ‘T+2’ norm as outlined in point [c] above.
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.info}>
                    All payments related to the sale of Albums / Memberships for
                    the Pre-Recorded Content area under the Video Vertical shall
                    be credited to your Ledger within 24 hours of the
                    transaction.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: " Limitation of Liability",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            If you are a Buyer / Customer of home businesses on ByzWiz and you
            are making payments during transactions between you and the home
            businesses on ByzWiz, we, the ByzWiz platform are not liable to any
            of claims made by you. The maxim Caveat Emptor, “Let the purchaser
            be careful” be held recognized. It is the obligation of the Buyer /
            Customer to determine aspects such as the quality and the
            convenience of the item he/she is buying before making any purchases
            and executing any payments. ByzWiz has no role to play in your
            decisions to engage with and buy from any home businesses listed on
            the ByzWiz platform.
          </p>
          <p className={styles.info}>
            If you are a Home Entrepreneur, who has signed up on the ByzWIz
            platform, has built, and is conducting and managing any number of
            Home Businesses on the ByzWiz platform, we the ByzWiz platform are
            not liable to any claims made by you, under any circumstances. You
            as a Home Entrepreneur understand and agree that the ByzWiz platform
            is a technology enabled platform and can therefore suffer occasional
            outages and failures. If for any reason, the functionality of the
            platform, and by extension therefore, the functionality of your home
            business/es are temporarily halted / impaired, you agree to not make
            any claims against the ByzWiz platform. You agree to not initiate
            any legal proceedings against the ByzWiz platform in a court of law,
            under the Indian legal system. You also agree that ByzWIz is not
            responsible in any manner for loss of your income or revenue; loss
            of business; loss of profits; loss of anticipated savings; loss of
            data; or waste of management or office time.
          </p>
          <p className={styles.info}>
            To the above-mentioned points, with respect to all users of the
            ByzWiz platform, we are responsible only as per our terms of
            service. If we fail to comply with these terms of service, our
            liability shall be limited to the transaction amount of the
            purchased Products and/or Services. We are not liable for any losses
            that you may suffer because of any Home Entrepreneur’s or Home
            business’ failure to comply with these terms of service, or of any
            users’ ability or inability to correctly use and operate the ByzWIz
            platform (whether arising in contract, delict (including
            negligence), and breach of statutory duty or otherwise).
          </p>
          <p className={styles.info}>
            We are not liable for losses that result from our failure to comply
            with these terms of service that fall into the following categories
            even if such losses were in our contemplation as at the date that
            the contract constituted by these terms of service was formed
            between us of being a foreseeable consequence of our breach: loss of
            income or revenue; loss of business; loss of profits; loss of
            anticipated savings; loss of data; or waste of management or office
            time.
          </p>
          <p className={styles.info}>
            The function of the ByzWIz platform is that of a facilitator /
            enabler / aggregator of home businesses created by Home
            Entrepreneurs for their Buyers/ Customers. We allow for such home
            businesses to operate and sell Products and Services for domestic
            and private use only. You as a Buyer / Customer of these Home
            Entrepreneurs and home businesses agree not to use the products
            available on the ByzWiz platform for any commercial, business or
            re-sale purposes, and that we have no liability to you for any loss
            of profit, loss of business, business interruption, or loss of
            business opportunity.
          </p>
          <p className={styles.info}>
            Our Home Entrepreneurs, through their home businesses that are
            listed on ByzWiz are fully liable for any of defects or neglects to
            their products and services as per these Terms of Service. At the
            sole discretion of the ByzWIz platform, in accordance with all legal
            requirements, the Home Entrepreneurs may be fully held legally
            liable of the law for failure to adhere to the terms of service as
            laid out in this document. The Home Entrepreneurs / home businesses’
            liability is subject to the policies and Terms of Service in force
            at the time that you the Buyer / Customer of said home businesses
            order the Products and/or Services using the ByzWiz platform, unless
            any changes to these policies or these Terms of Service is required
            to be made by law or governmental authority (in which case it may or
            may not apply to orders previously placed by you).
          </p>
          <p className={styles.info}>
            The ByzWiz Platform reserves the right to revise and amend these
            terms and conditions from time to time.
          </p>
        </div>
      ),
    },
    {
      title: "Indemnification",
      info: (
        <p className={styles.info}>
          You agree to indemnify and hold harmless The ByzWiz Platform from and
          against any and all losses, expenses, damages, and costs, including
          without limitation attorney’s fees, resulting, whether directly or
          indirectly, from your violation of the Terms of Service as outlined in
          this document. You also agree to indemnify and hold harmless The
          ByzWIz platform from and against any and all claims brought by any
          third parties arising out of your use of the ByzWiz platform.
        </p>
      ),
    },
    {
      title: "Intellectual Property Rights",
      info: (
        <p className={styles.info}>
          All the contents on the ByzWIz Platform and the ByzWiz Platform by
          itself, in its entirety, is the proprietary property of ByzWiz
          Infratech Private Limited, CIN U72900PN2022PTC209524, and all source
          code, databases, functionality, software, website designs, mobile
          application designs, tablet and web browser application designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of India, the United States, other foreign jurisdictions, and
          international conventions.
        </p>
      ),
    },
    {
      title: "Written Communications",
      info: (
        <p className={styles.info}>
          Applicable laws require that some of the information or communications
          we send to you, the users of the ByzWiz Platform, should be in
          writing. When using the ByzWiz platform, you accept that communication
          by us to you will be electronic. We will contact you and convey
          important information to you by email, Instant messaging, SMS, in-App
          Notifications, or provide you with information by posting notices on
          the ByzWIz platform. For contractual purposes you agree to this
          electronic means of communication and you acknowledge that all
          contracts, notices, information, and other communications that we
          provide to you electronically comply with any legal requirement that
          such communications be in writing. This condition does not affect your
          statutory rights.
        </p>
      ),
    },
    {
      title: "Notices",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            Any notice to be sent by you or by us in connection with these terms
            and condition can be sent by letter or by email. Notices to us
            should be sent to one of the following addresses:
          </p>
          <div>
            <p className={styles.info}>ByzWiz Infratech Private Limited,</p>{" "}
            <p className={styles.info}>C1/302, 4th Floor, Misty Moors Apts.,</p>{" "}
            <p className={styles.info}>SNO 13/1/1, Near Kad Nagar, Undri,</p>
            <p className={styles.info}>Pune MH 411060</p>
          </div>
          <p className={styles.info}>
            We will send notices to you by email to the email address that you
            supplied at the time of signing up to our platform.
          </p>
          <p className={styles.info}>
            Either of us can change the address for notices by informing the
            other in writing the new address, but the previous address will
            continue to remain valid for 7 days after the change is notified.
          </p>
          <p className={styles.info}>
            If sent to the correct address, a notice will be treated as received
            three (3) working days after sending, if sent by a letter, or 24
            hours if sent by email even if it is not actually received.
          </p>
        </div>
      ),
    },
    {
      title: "Rights and Disputes",
      info: (
        <div className={styles.contentMainBox}>
          <div>
            <p className={styles.subTitle}>Third Party Rights</p>
            <p className={styles.info}>
              A person who is not party to these Terms of Service or a Contract
              between You and US, shall not have any rights under or in
              connection with them.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>Waiver</p>
            <p className={styles.info}>
              The failure of either party to exercise or enforce any rights
              conferred on that party by these Terms of Service shall not be
              deemed to be a waiver of any such right or operate to bar the
              exercise or enforcement thereof at any time or times thereafter.
              No waiver by us of any of these Terms of Service will be effective
              unless it is expressly stated to be a waiver and is communicated
              to you in writing.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>Severability</p>
            <p className={styles.info}>
              If any court or competent authority decides that any of the
              provisions of these Terms of Service or any provisions of the
              Contract brought into force between Us and You through the
              applicability and your acceptance of these Terms of Service as a
              result of your use of the ByzWiz Platform, is invalid, unlawful or
              unenforceable to any extent, the Contract between Us and You shall
              stand severed and defunct, with no liability towards either party.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>Dispute Resolution</p>
            <p className={styles.info}>
              Any legal action of whatever nature brought by either you or us
              (collectively, the “Parties” and individually, a “Party”) shall be
              commenced or prosecuted in the courts located in Pune,
              Maharashtra, India, and the Parties hereby consent to, and waive
              all defenses of lack of personal jurisdiction and forum non
              convenience with respect to venue and jurisdiction in such state
              and other forums and tribunals. Application of the United Nations
              Convention on Contracts for the International Sale of Goods and
              the Uniform Computer Information Transaction Act (UCITA) are
              excluded from these Terms of Service. In no event shall any claim,
              action, or proceeding brought by either Party related in any way
              to the Platform be commenced more than 1 year after the cause of
              action arose.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>Binding Arbitration</p>
            <div className={styles.infoBox}>
              <p className={styles.info}>
                If the Parties are unable to resolve a dispute through informal
                negotiations, the dispute (except those disputes expressly
                excluded below) will be finally and exclusively resolved by
                binding arbitration. Any dispute or difference whatsoever
                arising between the parties out of or relating to the
                construction, meaning, scope operation or effect of this
                contract or the validity or the breach thereof shall be settled
                by arbitration in accordance with, commenced and conducted under
                the Arbitration And Conciliation (Amendment) Ordinance, 2020,
                The Arbitration and Conciliation (Amendment) Act, 2019,
                Arbitration and Conciliation Act, 1996 to two arbitrators one to
                be appointed by each party to the difference (whether consisting
                of one or more than one person) and in case of difference of
                opinion between them to an umpire appointed by the said two
                arbitrators before entering on the reference and the decision of
                the arbitrator ( or such arbitrators, or umpire as the case may
                be) shall be final and binding on the parties.
              </p>
              <p className={styles.info}>
                The arbitration may be conducted in person, through the
                submission of documents, by phone, or online. The arbitrator
                will decide in writing but need not provide a statement of
                reasons unless requested by either Party.
              </p>
              <p className={styles.info}>
                In no event shall any dispute brought by either Party related in
                any way to the Platform be commenced more than 1 year after the
                cause of action arose.
              </p>
              <p className={styles.info}>
                The arbitrator must follow the applicable law, and any award may
                be challenged if the arbitrator fails to do so.
              </p>
            </div>
          </div>
          <div>
            <p className={styles.subTitle}>Force Majeure</p>
            <p className={styles.info}>
              We reserve the right to defer the date of delivery or to cancel a
              Contract for all circumstances beyond its reasonable control,
              including but not limited to any strike, lockout, disorder, fire,
              explosion, accident or stoppage of or affecting our business or
              work, any acts of God; natural disaster (fires, explosions,
              earthquakes, hurricane, flooding, storms, explosions,
              infestations), epidemic, or pandemic; war, invasion, hostilities
              (whether war is declared or not), terrorist threats or acts, riot
              or other civil unrest; government order or law; actions, embargoes
              or blockades in effect on or after the date of this Agreement;
              action by any governmental authority; national or regional
              emergency; strikes, labor stoppages or slowdowns or other
              industrial disturbances; and shortage of adequate power or
              transportation facilities which prevents or hinders the delivery
              of the Goods or the performance of the Services.
            </p>
          </div>
          <div>
            <p className={styles.subTitle}>Law and Jurisdiction</p>
            <p className={styles.info}>
              These Terms of Service and any dispute or claim arising out of or
              in connection with them or their subject matter or formation
              (including non-contractual disputes or claims) will be governed by
              laws of the State of Maharashtra, India. Any dispute or claim
              arising out of or in connection with these terms and conditions or
              their formation (including non-contractual disputes or claims)
              will be subject to the non-exclusive jurisdiction of the courts of
              Pune, Maharashtra, India.
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Disclaimer",
      info: (
        <div className={styles.infoBox}>
          <p className={styles.info}>
            You, the User of the ByzWiz platform must accept this disclaimer in
            total for using the ByzWiz Platform. If you do not agree or have any
            reservations regarding this disclaimer or any part of this
            disclaimer, you must not use the platform. You must agree to our use
            of cookies on our websites as per our Terms of Service and our
            Privacy Policy. We reserve right to restrict to access our websites
            or any part of the ByzWiz Platform.
          </p>
          <p className={styles.info}>
            All information contained on this platform and on our websites and
            mobile/tablet/web applications is for public information purposes
            only.
          </p>
          <span className={styles.info}>
            The information is provided by and is available on{" "}
            <a
              href="https://www.byzwiz.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.linkText}>https://www.byzwiz.com</span>
            </a>
            ,{" "}
            <a
              href="https://byb.byzwiz.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className={styles.linkText}>https://byb.byzwiz.com</span>
            </a>{" "}
            the ByzWiz mobile/tablet/web application, which are cumulatively a
            part of the ByzWIz Platform and property of ByzWIz Infratech Private
            Limited.
          </span>
          <p className={styles.info}>
            While we endeavor to keep all the information available on the
            ByzWiz Platform up to date and correct, we make no representations
            or warranties of any kind, express or implied, about the
            completeness, accuracy, reliability, suitability or availability
            with respect to the platform or the information, products, services,
            or related graphics contained on the platform for any purpose. Any
            actions by you by relying on such information are therefore strictly
            at your own risk. In no event will we be liable for any loss or
            damage including without limitation, indirect or consequential loss
            or damage, or any loss or damage whatsoever arising out of, or in
            connection with, the use of the ByzWiz Platform.
          </p>
          <p className={styles.info}>
            Through the ByzWIz Platform you may be able to link to other
            websites which are not under the control of ByzWiz Infratech Private
            Limited. ByzWiz Infratech Private Limited has no control over the
            specifications, information, nature, content, and availability of
            those sites. The links to external websites do not necessarily
            suggest a recommendation, approval or endorsement of the views
            expressed within them by ByzWiz.
          </p>
          <p className={styles.info}>
            ByzWiz Infratech Private Limited endeavors to keep the entire ByzWiz
            Platform up and running smoothly. However, ByzWiz Infratech Private
            Limited takes no responsibility for, and will not be liable for, the
            website being temporarily unavailable due to technical issues beyond
            our control. Astaval Econet Private Limited takes no responsibility
            and does not warrant for advice, information, details or any kind of
            guidance or recommendation, obtained by you from this website not
            expressly stated in the website.
          </p>
          <p className={styles.info}>
            Regarding any product or services obtained or purchase through
            Astaval Econet Private Limited or its website, the defects will be
            corrected. Some of countries or legal jurisdictions do not allow the
            limitation or exclusion of particular warranties; in that case, the
            above exclusions may not apply and the other terms will be severable
            from the agreement and other terms shall remain in full force and
            effect. Astaval Econet Private Limited or its website makes no
            representation or recommendation that the Website or Products are
            appropriate for use in locations outside India. The access of the
            Website from outside India is at your own risk and initiative and
            you bear all responsibility for compliance with any applicable local
            laws in regards to the products, merchandise or services or use of
            the website. The rights and claims are not transferable and cannot
            be assigned to other persons or entities.
          </p>
          <p className={styles.info}>
            Astaval Econet Private Limited or its website is not responsible for
            any damages resulting from use of this website by anyone. We are not
            liable to you in respect of any losses arising out of any event or
            events beyond reasonable control. By using of this website you
            accept that the personal liability of the directors and employees is
            limited and you acknowledge and agree that we are a limited
            liability company; you agree that you will not initiate or bring any
            claim personally against any of our directors or employees in
            respect of any losses suffered in connection with the website or
            this disclaimer.
          </p>
          <p className={styles.info}>
            This Disclaimer is part of the terms and conditions of the service
            agreement for website use and you accept and bound by the same in
            total.
          </p>
        </div>
      ),
    },
    {
      title: "Contact Us",
      info: (
        <div>
          <div>
            <p className={styles.info}>ByzWiz Infratech Private Limited,</p>
            <p className={styles.info}>C1/302, 4th Floor, Misty Moors Apts.,</p>
            <p className={styles.info}>SNO 13/1/1, Undri,</p>
            <p className={styles.info}>Pune MH 411060,</p>
            <p className={styles.info}>INDIA.</p>
          </div>
          <div>
            <p
              className={styles.info}
              style={{
                paddingTop: "10px",
              }}
            >
              Email :{" "}
              <a href="mailto:support@byzwiz.com">
                <span className={styles.linkText}>support@byzwiz.com</span>
              </a>
            </p>
            <p className={styles.info}>
              Mobile : <span className={styles.info}>+91 7709375503</span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const [selectedId, setSelectedId] = useState(termsOfServices?.[0]?.title);
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  const isTabletPortrait = useMediaQuery({
    minWidth: 768,
    maxWidth: 1334,
    orientation: "portrait",
  });

  useEffect(() => {
    if (selectedId) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedId]);

  return (
    <div className={styles.mainContainer}>
      {!Boolean(isMobile || isTabletPortrait) && (
        <>
          <div className={styles.sideContainerMeta}></div>
          <div className={styles.sideContainer}>
            {(termsOfServices || []).map((item, index) => {
              return (
                <p
                  key={index}
                  className={styles.title}
                  onClick={() => setSelectedId(item?.title)}
                  style={{
                    fontWeight: selectedId === item?.title ? 600 : 400,
                    color: selectedId === item?.title ? "#353445" : "#5c5b69",
                    cursor: selectedId === item?.title ? "default" : "pointer",
                  }}
                >
                  {item?.title}
                </p>
              );
            })}
          </div>
        </>
      )}
      {!Boolean(isMobile || isTabletPortrait) ? (
        <div className={styles.mainContentContainer}>
          <p className={styles.mainTitle}>Terms of Service</p>
          <div className={styles.containerBox}>
            {termsOfServices.map((item, index) => {
              if (selectedId === item?.title) {
                return (
                  <div key={index}>
                    <p className={styles.mainSubTitle}>{item?.title}</p>
                    {item?.info}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className={styles.mainContentContainer}>
          <p className={styles.mainTitle}>Terms of Service</p>
          <div className={styles.containerBox}>
            {termsOfServices.map((item, index) => {
              return (
                <div key={index}>
                  <p className={styles.mainSubTitle}>{item?.title}</p>
                  {item?.info}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
export default TermsOfServices;
