import React, { useState } from "react";
import styles from "./SendLinkModal.module.scss";
import sendIcon from "assets/icons/sendIcon.svg";
import playstoreLinkQR from "assets/images/playStoreQr.png";
import appletoreLinkIcon from "assets/images/appleStoreQr.png";
import playstoreMobileLinkIcon from "assets/icons/playstoreLinkIcon.png";
import applestoreLinkIcon from "assets/icons/applestoreLinkIcon.png";
import closeIcon from "assets/icons/closeIcon.png";
import { useMediaQuery } from "react-responsive";
import ActiveSendIcon from "assets/icons/ActiveSendIcon.svg";
import { ShareApp } from "api/ShareApp";
import toast from "react-hot-toast";
type SendLinkModalProps = {
  open: boolean;
  handleModal: () => void;
};
type responseType = {
  success: boolean;
  message: string;
};
function SendLinkModal({ open, handleModal }: SendLinkModalProps) {
  const [link, setLink] = useState("Get");
  const [inputVal, setInputVal] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });
  const handleSubmit = () => {
    ShareApp({ mobile: inputVal })
      .then(async (res) => {
        const data = (await res?.data) as responseType;
        if (data?.success) {
          toast.success(data?.message, {
            position: "bottom-center",
            duration: 2000,
          });
          setInputVal("");
          handleModal();
        } else {
          toast?.error(data?.message, {
            position: "bottom-center",
            duration: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (!open) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.boxContainer}>
        <div
          className={styles.closeIconContainer}
          onClick={() => handleModal()}
        >
          <img src={closeIcon} className={styles.closeIcon} alt="closeIcon" />
        </div>
        <div className={styles.modalBody}>
          <p className={styles.title}>The ByzWiz App</p>
          <div className={styles.contentContainer}>
            <div>
              <p className={styles.desc}>
                Enter phone number to get the link via SMS
              </p>
              <div className={styles.inputContainer}>
                <p className={styles.number}>+91</p>
                <input
                  type="number"
                  className={styles.input}
                  onChange={(e) => setInputVal(e.target.value)}
                />
                {inputVal?.length === 10 ? (
                  <img
                    src={ActiveSendIcon}
                    className={styles.activeSendIcon}
                    onClick={() => handleSubmit()}
                  />
                ) : (
                  <img src={sendIcon} className={styles.activeSendIcon} />
                )}
              </div>
            </div>
            <div className={styles.dFlex}>
              {isMobile && <p className={styles.divider}></p>}
              <p className={styles.orText}>OR</p>
              {isMobile && <p className={styles.divider}></p>}
            </div>
            <div className={styles.linkStoreContainer}>
              {!isMobile && (
                <img
                  src={playstoreLinkQR}
                  className={styles.sendStoreIconQR}
                  alt="Send play store Link Icon"
                />
              )}
              {!isMobile && (
                <img
                  src={appletoreLinkIcon}
                  className={styles.sendStoreIconQR}
                  alt="Send play store Link Icon"
                />
              )}
              {isMobile && <a href="https://byzwiz.com/download" target="_blank">
                <img
                  src={playstoreMobileLinkIcon}
                  className={styles.sendStoreIcon}
                  alt="Send play store Link Icon"
                />
              </a>}
              {isMobile && <a href="https://apps.apple.com/in/app/byzwiz/id6504817779" target="_blank">
                <img
                  src={applestoreLinkIcon}
                  className={styles.sendStoreIcon}
                  alt="Send apple store Link Icon"
                />
              </a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SendLinkModal;
