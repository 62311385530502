import { useEffect, useState } from "react";
import styles from "./PrivacyPageStyles.module.scss";
import { useMediaQuery } from "react-responsive";
import closeArrowIcon from "assets/icons/closedArrow.svg";
import ActiveArrowIcon from "assets/icons/ActiveArrowIcon.svg";

const privacyPolicy = [
  {
    title: "Preamble",
    subTitle: [
      "Scope and Definitions",
      "Purpose",
      "Consent and Acceptance",
      "Review",
    ],
    data: [
      {
        subTitle: "Scope and Definitions",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`This document, labelled “Privacy Policy” along with included references to any other documents, describes and explains how the ByzWiz Platform (Hereinafter referred to as ByzWiz, The Platform, The Website, The App, We, Us, Our/s, or a reference deemed appropriate within context) may acquire, gather, save, manage, share, analyse, examine and utilise the Data / Personal information, which is either voluntarily shared or is available during the use of the ByzWiz Platform by its Users.`}
            </p>
            <div>
              <p className={styles.infoText}>
                {`‘Users’, in general, refers to anyone who may use the ByzWiz platform, as laid out below:`}
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Home Entrepreneurs and their Customers, also referred to as Sellers and Buyers.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Visitors, implying any Person (Identifiable via the sign-up process or anonymous) browsing / accessing / using the services provided by the ByzWiz Platform.`}</p>
                </li>
              </ol>
            </div>
            <p className={styles.infoText}>
              {`Any User who has signed up to use the ByzWiz platform is referred to as a ‘Registered User’.`}
            </p>
            <p
              className={styles.infoText}
            >{`Users may also be referred to as ‘You’ / ‘Yours’.`}</p>
            <p
              className={styles.infoText}
            >{`The terms ‘Data / Personal Information’ generally means information that could identify Users either directly or by inference. Such Data / Personal Information can either be provided directly and voluntarily to the platform by the Users or collected by the platform by virtue of the Users being present on the platform.`}</p>
            <p
              className={styles.infoText}
            >{`This document also describes the options available to all the Users of the ByzWiz platform regarding the use, access and correction of their Data / Personal Information and the pertinent rights regarding their Data / Personal Information.`}</p>
          </div>
        ),
      },
      {
        subTitle: "Purpose",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`The ByzWiz Platform is built and maintained by ByzWiz Infratech Private Ltd., (Hereinafter referred to as ‘The Company’) with the following purposes:`}
            </p>
            <ol className={styles.list}>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`To provide the best possible features and tools to enable Home Entrepreneurs to build, manage and conduct multiple Home Businesses online, free of cost across all three (3) verticals, Food, Products and Video.`}</p>
              </li>
              <li className={styles.listItem}>
                <p className={styles.infoText}>
                  {`To ensure that the said Home Entrepreneurs can provide premium exploration, purchasing, and social engagement experiences to their Customers / Buyers and other visitors on the platform.`}
                </p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`To implement and encourage ‘True Social Commerce’.`}</p>
              </li>
            </ol>
            <p
              className={styles.infoText}
            >{`To this end, we value your trust in the platform when you share your Data / Personal Information with us. Securing everything you share with us is of prime important for us.`}</p>
          </div>
        ),
      },
      {
        subTitle: "Consent and Acceptance",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`By using / visiting the ByzWiz platform and / or by providing any information to avail any services offered by the platform, users grant ByzWiz clear consent to the collection, use, storage, disclosure and otherwise processing of user information (including sensitive personal information) on the Platform in accordance with this Privacy Policy. Users also expressly accept and agree to be bound by the terms outlined in this Privacy Policy and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If users choose to disclose to us any personal information relating to other people (such as by consenting and assuming responsibility as a Parental authority to facilitate their children to use ByzWiz for their entrepreneurial ventures, and in doing so, share their children’s personal information) , the user represents that the user has the authority to do so and to permit ByzWiz to use the information in accordance with this Privacy Policy.`}</p>
            <p
              className={styles.infoText}
            >{`All users, while providing their personal information over the ByzWiz Platform or any partner platforms or establishments, consent to and permit us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact the users through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.`}</p>
            <p
              className={styles.infoText}
            >{`Users that do not agree to the terms outlined in this Privacy Policy, are advised to not access, or use the ByzWiz Platform.`}</p>
          </div>
        ),
      },
      {
        subTitle: "Review",
        info: (
          <p className={styles.infoText}>
            {`This Privacy Policy will be reviewed and updated from time to time to ensure that our policies remain current and compliant.The contents of this document are therefore subject to change at any time without notice. Anonymous visitors are advised to review this document periodically on their own accord to remain updated with our policies. Registered Users will be notified when changes are made to this document and are advised to review the revised policies before continuing to use the platform.`}
          </p>
        ),
      },
    ],
  },
  {
    title: "Collection of Data / Personal Information",
    subTitle: [
      "Anonymous access information",
      "Identified access information",
      "Objective for collection of information",
      "Type of Data / Personal Information collected",
      "Declarations regarding collected information",
    ],
    data: [
      {
        subTitle: "Anonymous access information",
        info: (
          <p className={styles.infoText}>
            {`When you use the ByzWiz Platform, we collect and store your information which is available to us or provided by you from time to time. As an unidentified visitor, you may explore the platform anonymously to learn more about it, without telling us who you are or providing any Personal Information. Whenever you visit the platform anonymously, we collect and store certain available information to improve security, analyse trends, and administer the platform to assist us in enhancing the user experience. We use this information to evaluate traffic patterns on our platform to make ByzWiz more beneficial to our users.`}
          </p>
        ),
      },
      {
        subTitle: "Identified access information",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`We collect your Personal Information when you register with us with the intention to use the ByzWiz platform, express an interest in obtaining information about us or our products and services, participate in activities on our platform or otherwise contact us.`}
            </p>
            <div>
              <p className={styles.infoText}>
                {`We collect your Personal Information:`}
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.infoText}>
                    {`To comply with our legal and regulatory obligations.`}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`To implement our contract with you. `}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`To prepare and articulate before entering a contract with you`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`For our legitimate interests, where permitted by law or those of a third party where you have consented.`}</p>
                </li>
              </ol>
            </div>
            <p className={styles.infoText}>
              {`In some instances, we may be required by law to collect Personal Information from you or use available information about you to protect your vital interests (for example, to confirm your identity to prevent payment fraud).`}
            </p>
            <p className={styles.infoText}>
              {`If you do not wish to share or provide any Personal Information, you may choose to not use the Platform.`}
            </p>
          </div>
        ),
      },
      {
        subTitle: "Objective for collection of information",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`Our primary objective in collecting your Data / Personal Information is to provide you with a secure, efficient, seamless, and personalised experience while using the ByzWiz Platform. To enable this, we may track your preferences, purchasing and general behaviour, activity related information or other information that you either choose to provide on our Platform, or is available to us by virtue of you being present on the platform.`}
            </p>
            <p className={styles.infoText}>
              {`This information is aggregated and compiled to enable internal research and analysis of user interests, behaviour, demographics, and other related parameters to better understand, personalise and enhance your experience on ByzWiz, and to improve the content and functionality of ByzWiz.`}
            </p>
          </div>
        ),
      },
      {
        subTitle: "Type of Data / Personal Information collected",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`When you visit the ByzWiz platform and go through the registration process to use the platform, we collect Personal Information that you voluntarily provide to us via one or more of our registration or contact forms, or other means of communication built into the platform, such as a chat interface or email, or other methods of digital / phone communications. We also collect other information that is available to us incidentally by your presence on the platform.`}
            </p>
            <div>
              <p className={styles.infoText}>
                {`We collect your Personal Information:`}
              </p>
              <ol className={styles.list}>
                <li className={styles.listItem}>
                  <p className={styles.infoText}>
                    {`Contact Information including your Name, Email address, Postal address, Mobile phone number and your display pictures, IP addresses and geographic locations.`}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Your Date of birth and KYC documents such as PAN / Aadhar / Passport / Voter ID.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information regarding the various devices, operating systems, software, browsers, internet services used to access the ByzWIz platform.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information relevant to home businesses such as inventory description, pictures and videos, prices, and all other home business details, settings, and parameters.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Billing Information and billing / delivery addresses.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Payment related information such as transaction numbers etc. that may or may not be stored with us.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Unique identifiers, such as your usernames and passwords.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Bank account details to facilitate withdrawals from ByzWiz.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Your preferences / selections, account settings to personalise your experience.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information provided by you such as reviews, ratings, feedback, complaints, requests, suggestions etc. through the various mechanisms of communication made available to you on the ByzWiz platform.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information shared while interacting with ByzWiz customer support such as emails, chat transcripts, phone call recordings etc. on the ByzWiz platform.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Any information shared on social media accounts / handles maintained by us.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Responses to surveys that we may run from time to time.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`URL information (from where you came and which URL you go to next, whether said URLs are of our platform or not) and your computer browser information.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information shared by you on the platform while in communication with other users of the platform, such as Chat messages, Video sessions etc.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.infoText}>{`
                        Information regarding all your activities, usage statistics, conduct and behavioural characteristics on the platform..`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information regarding placing orders, conducting transactions, or consuming online content or services either provided by us as a platform or by other users of the platform.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information related to your transactions on the ByzWiz platform via third-party business partner platforms such as the integrated payment gateways or logistics service providers.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Information obtained through personal correspondence such as emails or letters or files shared by you or third parties regarding you.`}</p>
                </li>
                <li className={styles.listItem}>
                  <p
                    className={styles.infoText}
                  >{`Any other information deemed necessary to provide services, provide new features, resolve disputes, provide customer support, troubleshoot problems or for internal research and analysis as permitted by law.`}</p>
                </li>
              </ol>
            </div>
          </div>
        ),
      },
      {
        subTitle: "Declarations regarding collected information",
        info: (
          <div>
            <ol style={{ paddingLeft: "15px" }}>
              <li className={styles.listItem}>
                <p className={styles.infoText}>
                  {`We do not voluntarily make the collected information available to third parties, nor do we use it for any purpose other than those stated in this Privacy Policy. The Data / Personal Information collected by us will not be sold to any entity / third party for monetary gains.`}
                </p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`User’s names, social profiles, business fronts, display pictures, inventory pictures, thumbnails, introduction videos etc. will be displayed publicly on the ByzWiz platform for all visitors to see and engage with.`}</p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`The collected information may be shared with certain third-party service providers to avail services such as payments, logistics, analytics, etc. By agreeing to this Privacy Policy, you provide your explicit consent to share your Data / Personal Information with these third-party service providers.`}</p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`The above-mentioned third-party service providers conduct their businesses / provide services in accordance with their own Privacy Policies. Users are advised to review these documents on their own accord. The links to such documents will be made available to our users by the ByzWiz platform. ByzWiz shall not be responsible for the privacy practices or the content of their (Third-Party service provider’s) privacy policies, and we request you to read their privacy policies prior to disclosing any information.`}</p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`We do not save or store with us any payment related information provided by users to complete purchase transactions on ByzWiz. All payment related information is handled and stored directly with the payment gateways that we have integrated into the ByzWiz platform to facilitate online payments.`}</p>
              </li>
              <li className={styles.listItem}>
                <p
                  className={styles.infoText}
                >{`We may use technologies such as cookies, clear gifs, log files, and flash cookies for multiple reasons, including to better understand how you interact with our platform and services to provide better a user experience.`}</p>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    title: "Use and Retention of Collected Data / Personal Information",
    subTitle: [
      "Fundamental usage",
      "Improvement of services",
      "Other Modes of Information Collection & uses",
      "Use of Cookies",
    ],
    data: [
      {
        subTitle: "Fundamental usage",
        info: (
          <p className={styles.infoText}>
            {`We use the Data / Personal Information we collect to provide the services you request, to exercise or comply with legal rights or obligations, and for other business purposes as described in this policy.`}
          </p>
        ),
      },
      {
        subTitle: "Improvement of services",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`We will use your Personal Information to provide products and services as requested, analyse, manage, and improve our services, to provide you with a personalised experience on the platform and to contact you about your account and our services, to provide you with service and support.`}</p>
            <p
              className={styles.infoText}
            >{`We also use your personal information to assist sellers and business partners in handling and fulfilling orders; enhancing customer experience; resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services; inform you about online and offline offers, products, services, and updates; customize and enhance your experience; detect and protect against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described at the time of collection of information.`}</p>
            <p className={styles.infoText}>
              {`Whenever personal data and information is used by us for the purposes of marketing to our users, we will provide the users with the ability to choose whether they wish to participate in such activities or opt-out.`}
            </p>
          </div>
        ),
      },
      {
        subTitle: "Other Modes of Information Collection & uses",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`With the expressed consent of the users of the ByzWiz platform, we may gain access to your device locations from time to time to determine your general geographic location to provide with services that depend upon such location data, such as logistics. We may also have access to SMS’s, instant messages, contacts, photos, device information etc. to fundamentally make available features and processes that improve user experience.`}
            </p>
            <div>
              <p className={styles.infoText}>
                {`We may request information to determine user identity through KYC (Know-Your-Customer) related information such as PAN and/or other government issued IDs / numbers such as GST, Aadhar, Voter Id, Passport etc. for the said purpose:`}
              </p>
              <ol className={styles.discList}>
                <li className={styles.listItem}>
                  <p className={styles.infoText}>
                    {`To determine user eligibility for certain products, services, lines of credit etc.`}
                  </p>
                </li>
                <li className={styles.listItem}>
                  <p className={styles.infoText}>
                    {`Raise GST invoices on behalf of our Home Entrepreneurs / sellers if they are GST registered.`}
                  </p>
                </li>
              </ol>
            </div>
            <p className={styles.infoText}>
              {`Users may choose to not provide consent for such data to be collected by us, in which case, We as a platform will not be able to provide access to such relevant products and services.`}
            </p>
            <p className={styles.infoText}>
              {`We and our affiliates collect and analyse demographic and profile data about our users' activity on our Platform. We identify and record users IP address to identify and gather user demographic information, help identify and resolve problems with our servers, and to manage the platform in our efforts to continually improve our product and service offerings.`}
            </p>
          </div>
        ),
      },
      {
        subTitle: "Use of Cookies",
        info: (
          <p
            className={styles.infoText}
          >{`The ByzWiz platform uses "Cookies" to track user behavioural and other user preference related information on the platform. A cookie is a small file carrying snippets of data that is stored on your computer or mobile device by your web browser. Among other things, cookies are used to customise the content users see on the platform. Cookies can be disabled in most web browsers. However, if disabled, users may not be able to access the platforms functionality correctly or at all. Cookies do not include Personally Identifiable Information.`}</p>
        ),
      },
    ],
  },
  {
    title: "Sharing of Personal Information",
    subTitle: [
      "Sharing with Internal Entities",
      "Sharing with External Entities",
    ],
    data: [
      {
        subTitle: "Sharing with Internal Entities",
        info: (
          <p
            className={styles.infoText}
          >{`User data / Personal Information may be shared within the various internal departments and/or group companies and other users / affiliates of ByzWiz to process requests and enable features, or for certain processes and/or analysis resulting in better user experience.`}</p>
        ),
      },
      {
        subTitle: "Sharing with External Entities",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`ByzWiz uses other companies, agents, and independent contractors ("Service Providers") to perform services on behalf of or to assist ByzWiz in providing services. Service Providers are used to provide marketing, advertising, communications, infrastructure, and IT services, to personalise and optimise our service, to process credit card transactions or other payment methods, to provide customer service, to analyse and enhance data (including data about users' interactions with our service), and to process and administer consumer surveys. While providing these services, these Service Providers may have access to personal or other user related information. ByzWiz does not authorise them to use or disclose users’ personal information outside of the scope of providing their services. ByzWiz does not disclose such personal information to third parties for marketing and advertising purposes without the users’ explicit consent.`}</p>
            <p
              className={styles.infoText}
            >{`Information may also be shared with service providers external to the ByzWiz platform for other reasons, such as; fulfilment / delivery of orders; for enhancing user experience; for providing feedback on products and services; to collect and release payments; to comply with legal obligations; to conduct market research or surveys; to enforce the ByzWiz Terms of Service; to facilitate marketing and advertising activities to propagate the ByzWiz Platform; to analyse data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to the platforms products and services. 
                
                `}</p>
            <p
              className={styles.infoText}
            >{`ByzWiz may share user data / personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. ByzWiz may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Service or Privacy Policy; respond to claims that an advertisement, posting, or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the public.`}</p>
          </div>
        ),
      },
    ],
  },
  {
    title: "Security of Personal Information",
    subTitle: [
      "Security Precautions",
      "Consent and Opt-Out",
      "Children's Information on ByzWiz",
      "Data Retention",
    ],
    data: [
      {
        subTitle: "Security Precautions",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`The ByzWiz Platform maintains reasonable physical, electronic, and procedural safeguards to protect information, and every effort is made to protect users’ personal information / data both online and offline. Stringent security guidelines and protocols are employed and adhered to, so that users’ personal information and other information is protected against unauthorised access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding the use of the Platform. `}</p>
            <p
              className={styles.infoText}
            >{`ByzWiz employs appropriate security measures to prevent users’ personal information from being inadvertently lost, used, or accessed in an unauthorised manner. Users’ personal information will only be processed in an authorised manner and subject to a confidentiality obligation. The platform has protocols in place to address any suspected data security breach. If required by law, ByzWiz will inform users and/or the applicable regulatory authorities about any suspected data security breach. ByzWiz shall also co-operate with any allied information required to be provided under applicable law. `}</p>
            <p
              className={styles.infoText}
            >{`ByzWiz cannot guarantee the absolute security of users’ personal and related information, and by using the ByzWiz platform, you accept the terms of this privacy policy, and you expressly waive your right to pursue legal proceedings / action against ByzWiz or any associated entities for any breach / loss of data and personal information in any court of law.`}</p>
            <p className={styles.infoText}>
              {`If you believe your account has been compromised or abused, please contact us at`}
              <span className={`${styles.infoText} ${styles.spanText} `}>
                <a href="https://byb.byzwiz.com/support">{`support@byzwiz.com`}</a>
              </span>
            </p>
            <p className={styles.infoText}></p>
          </div>
        ),
      },
      {
        subTitle: "Consent and Opt-Out",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`ByzWiz does not share, sell, transfer or rent your Personal Information to third parties for their marketing purposes without the users’ explicit consent. ByzWiz only uses collected and incidentally available user information in accordance with this Privacy Statement. The ByzWiz platform views the protection of user privacy as a prime requirement and therefore, extremely essential. We recognise that our users and their personal information are amongst our most valuable assets. `}</p>
            <p
              className={styles.infoText}
            >{`ByzWiz provides all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us. Users who do not wish to receive promotional communications from the ByzWiz platform and/or allied entities, can do so by selecting suitable settings made available to all users in the appropriate areas of the platform.`}</p>
          </div>
        ),
      },
      {
        subTitle: "Children's Information on ByzWiz",
        info: (
          <p
            className={styles.infoText}
          >{`The use of the ByzWiz Platform is available to persons who can form a legally binding contract under the Indian Contract Act, 1872. The ByzWiz platform gives an opportunity to children between the ages of 13-18 years to explore entrepreneurship and try out their unique and creative Home Business ideas under the guidance and responsibility of their parents. We do not solicit or collect personal information from children under the age of 18 years without clear parental consent. If a parent has shared personal information of their children under the age of 18 years, the said parent represents that they have the authority to do so and permit us to use the information in accordance with this Privacy Policy.`}</p>
        ),
      },
      {
        subTitle: "Data Retention",
        info: (
          <p
            className={styles.infoText}
          >{`ByzWiz retains the personal information of its users in accordance with appliable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, ByzWiz may retain data related to its users if it is believed that it may be necessary to prevent fraud or future abuse, to enable ByzWiz to exercise its legal rights and/or defend against legal claims or if required by law or for other legitimate purposes. ByzWiz may continue to retain your data securely and in anonymised form for analytical and research purposes.`}</p>
        ),
      },
    ],
  },
  {
    title: "Hyperlinks",
    subTitle: [],
    data: [
      {
        subTitle: "",
        info: (
          <p
            className={styles.infoText}
          >{`The ByzWiz Platform may provide links to other websites or application that may collect personal information about our users. ByzWiz is not responsible for the privacy practices or the content of those linked websites, nor for any third party to whom ByzWiz will share user data and information in accordance with this Privacy Policy.`}</p>
        ),
      },
    ],
  },
  {
    title: "Rights",
    subTitle: [
      "Prohibition on Misuse",
      "Trademarks and Copyrights",
      "Obligation for Intimation",
      "User content",
      "Correction and Rectification",
      "Withdrawal of Consent",
    ],
    data: [
      {
        subTitle: "Prohibition on Misuse",
        info: (
          <p
            className={styles.infoText}
          >{`ByzWiz holds all the intellectual property rights, copyrights, and licenses by creating and deploying the ByzWiz platform for use by the general public in the intended manner of use. Any other form of usage of the ByzWIz Platform or its contents is strictly prohibited. Users are prohibited from modifying, copying, distributing, transmitting, displaying, printing, selling, licensing, creating derivative works, or using any content found and accessible on the ByzWiz platform for any purposes other than those for which this document and the ByzWIz Terms of Service allow. Replication, copying, reverse engineering, data breach or any such nefarious activity in any form is strictly prohibited and will be liable for legal action and prosecution to the fullest extent of all applicable laws.`}</p>
        ),
      },
      {
        subTitle: "Trademarks and Copyrights",
        info: (
          <p
            className={styles.infoText}
          >{`ByzWiz holds all the intellectual property rights, copyrights, and licenses by creating and deploying the ByzWiz platform for use by the general public in the intended manner of use. Any other form of usage of the ByzWIz Platform or its contents is strictly prohibited. Users are prohibited from modifying, copying, distributing, transmitting, displaying, printing, selling, licensing, creating derivative works, or using any content found and accessible on the ByzWiz platform for any purposes other than those for which this document and the ByzWIz Terms of Service allow. Replication, copying, reverse engineering, data breach or any such nefarious activity in any form is strictly prohibited and will be liable for legal action and prosecution to the fullest extent of all applicable laws.`}</p>
        ),
      },
      {
        subTitle: "Obligation for Intimation",
        info: (
          <p
            className={styles.infoText}
          >{`ByzWiz as a company owns and reserves all the rights to the selection, co-ordination, arrangement, revision, modification and enhancement of the contents, visual designs, design elements, page layouts, operability, and functionality of the ByzWiz Platform without any notice. The ByzWiz platform has no explicit obligation or responsibility to provide users with indications, markings, or any other information that may assist users in determining whether the content in question is copyrighted or trademarked. Users are solely responsible for any damage resulting from any infringement of copyrights, trademarks, or proprietary rights, or any other harm resulting from such actions.`}</p>
        ),
      },
      {
        subTitle: "User content",
        info: (
          <p
            className={styles.infoText}
          >{`By submitting any original content to any public area of the ByzWiz Platform, users warrant that they are the owners of such content and expressly grant the ByzWiz platform royalty-free, perpetual, irrevocable, non-exclusive right and licence to use, reproduce, modify, adapt, publish, translate and distribute such content (in whole or in part) worldwide and/or to incorporate it in other works in any form, media, or technology now known or later developed for the full term of any copyright that may exist in such content. Users also permit any other users of the ByzWiz platform to access and view and in legitimate ways utilise the content such that the user requests are fulfilled. Home Entrepreneurs / Users of the ByzWiz platform are clearly advised to upload to the platform only original content, created by themselves. Any copyright claims or legal actions initiated by entities that claim ownership of content uploaded by users will be contested by the users on whose accounts such content is found. The ByzWiz platform will not be held liable for any copyright violations in any such cases.`}</p>
        ),
      },
      {
        subTitle: "Correction and Rectification",
        info: (
          <p
            className={styles.infoText}
          >{`The platform takes every reasonable step to ensure that users’ personal information that we process is accurate and, where necessary, kept up to date, and any of the users’ personal information that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified. Users may access, correct, and update their personal information directly through the functionalities provided on the Platform. Users may delete certain non-mandatory information by logging into the platform and modifying appropriate settings in relevant sections available on the platform. Users can also write to us at the contact information provided below to assist with such requests.`}</p>
        ),
      },
      {
        subTitle: "Withdrawal of Consent",
        info: (
          <p
            className={styles.infoText}
          >{`Users have the option to withdraw consent that the users have already provided by writing to us at the contact information provided below. Please mention the words “Consent withdrawal” in the subject line of your communication. The platform will verify such requests before acting upon the requests. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related Terms of Service and applicable laws. In the event a user withdraws consent that was earlier given to the platform under this Privacy Policy, such withdrawal may hamper access to the Platform or restrict provision of services to the users for which the platform deems that information to be necessary.`}</p>
        ),
      },
    ],
  },
  {
    title: "General",
    subTitle: [
      "Changes to this Privacy Policy",
      "Law and Jurisdiction",
      "Disclosure of Information scenarios",
      "Grievance Officer",
    ],
    data: [
      {
        subTitle: "Changes to this Privacy Policy",
        info: (
          <p
            className={styles.infoText}
          >{`Please revisit this Privacy Policy periodically for modifications. The ByzWiz platform may update this Privacy Policy to reflect changes to our information practices. We will alert users to significant changes by posting the date our policy has been last updated, placing a notice on the Platform, or by sending an email when we are required to do so by applicable law.`}</p>
        ),
      },
      {
        subTitle: "Law and Jurisdiction",
        info: (
          <div className={styles.contentContainer}>
            <p
              className={styles.infoText}
            >{`In the event that a dispute arises between the parties regarding this Agreement, it shall be referred to the Sole Arbitrator and arbitrated in accordance with the Arbitration and Conciliation Act of 1996. The arbitration proceedings shall be conducted in English. PUNE shall be the location of the arbitration, and the arbitrator's decision shall be final and binding on both parties.`}</p>
            <p
              className={styles.infoText}
            >{`This agreement is subject to the exclusive jurisdiction of the courts in Pune, India, and is governed by Indian law.`}</p>
          </div>
        ),
      },
      {
        subTitle: "Disclosure of Information scenarios",
        info: (
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <p
                className={styles.infoText}
              >{`As required by law, such as to comply with a subpoena or other legal processes.`}</p>
            </li>
            <li className={styles.listItem}>
              <p
                className={styles.infoText}
              >{`To enforce applicable Terms of Service, including investigation of potential violations.`}</p>
            </li>
            <li className={styles.listItem}>
              <p
                className={styles.infoText}
              >{`When we believe in good faith (doctrine of uberrima fides) that disclosure is necessary to protect our rights, protect user safety or the safety of others, investigate fraud, address security or technical issues, or respond to a government request.`}</p>
            </li>
            <li className={styles.listItem}>
              <p
                className={styles.infoText}
              >{`With our trusted service providers who work on our behalf, do not have independent use of the information we disclose to them, and have agreed to and adhere to the rules outlined in this policy.`}</p>
            </li>
          </ul>
        ),
      },
      {
        subTitle: "Grievance Officer",
        info: (
          <div className={styles.contentContainer}>
            <p className={styles.infoText}>
              {`In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:`}
            </p>
            <p className={styles.infoText}>
              {`Mr. Arvinder Pal Singh Sachdev`}
            </p>
            <div>
              <p className={styles.infoText}>{`Designation: Founder & CEO`}</p>
              <p className={styles.infoText}>
                {`ByzWiz Infratech Private Limited,`}
              </p>
            </div>
            <div>
              <p className={styles.infoText}>
                C1/302, 4th Floor, Misty Moors Apts.,
              </p>
              <p className={styles.infoText}>SNO 13/1/1, Undri,</p>
              <p className={styles.infoText}>Pune MH 411060,</p>
              <p className={styles.infoText}>INDIA.</p>
            </div>
            <p className={styles.infoText}>
              {`Email :`}
              <a href="mailto:support@byzwiz.com">
                <span className={`${styles.infoText} ${styles.spanText} `}>
                  support@byzwiz.com
                </span>
              </a>
              <p className={styles.infoText}>
                Mobile : <span> +91 7709375503</span>
              </p>
            </p>
          </div>
        ),
      },
    ],
  },
];
const PrivacyPolicyPage = () => {
  const [selectedId, setSelectedId] = useState<string>(
    privacyPolicy?.[0]?.title,
  );
  const isMobile = useMediaQuery({ query: "(max-width:600px)" });

  const isTabletPortrait = useMediaQuery({
    minWidth: 768,
    maxWidth: 1334,
    orientation: "portrait",
  });

  useEffect(() => {
    if (selectedId) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [selectedId]);

  return (
    <div className={styles.mainContainer}>
      {!Boolean(isMobile || isTabletPortrait) && (
        <>
          <div className={styles.sideContainerMeta}></div>
          <div className={styles.sideContainer}>
            {(privacyPolicy || []).map((item, index) => {
              return (
                <p
                  key={index}
                  className={styles.title}
                  onClick={() => setSelectedId(item?.title)}
                  style={{
                    fontWeight: selectedId === item?.title ? 600 : 400,
                    color: selectedId === item?.title ? "#353445" : "#5c5b69",
                    cursor: selectedId === item?.title ? "default" : "pointer",
                  }}
                >
                  {item?.title}
                </p>
              );
            })}
          </div>
        </>
      )}
      {!Boolean(isMobile || isTabletPortrait) ? (
        <div className={styles.mainContentContainer}>
          <p className={styles.mainTitle}>Privacy Policy</p>
          <div className={styles.contentBox}>
            {(privacyPolicy || [])?.map((item, index) => {
              if (selectedId === item?.title) {
                return (
                  <div key={index}>
                    <p className={styles.mainPoint}>{item?.title}</p>
                    <div className={styles.columnFlex}>
                      {(item?.data || []).map((ite, ind) => {
                        return (
                          <div key={ind}>
                            {Boolean(ite?.subTitle) && (
                              <p className={styles.points}>{ite?.subTitle}</p>
                            )}
                            {ite?.info}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : (
        <div className={styles.mainContentContainer}>
          <p className={styles.mainTitle}>Privacy Policy</p>
          {(privacyPolicy || [])?.map((item, index) => {
            return (
              <div key={index}>
                <div className={styles.titleContainer}>
                  <p className={styles.mainPoint}>{index + 1}.</p>
                  <p className={styles.mainPoint}>{item?.title}</p>
                </div>
                <div className={styles.columnFlex}>
                  {(item?.data || []).map((ite, ind) => {
                    return (
                      <div key={ind}>
                        {Boolean(ite?.subTitle) && (
                          <p className={styles.points}>{ite?.subTitle}</p>
                        )}
                        {ite?.info}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicyPage;
