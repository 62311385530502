import TermsOfServices from "pages/services-terms";
import LandingPage from "./pages/LandingPage";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import { Routes, Route, Navigate } from "react-router-dom";
import { useEffect } from "react";
import DownloadPage from "./pages/DownloadPage";
import NewLandingPage from "pages/NewLandingPage/NewLandingPage";

const RedirectToPlayStore = () => {
  useEffect(() => {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.byzwiz",
    );
  }, []);

  return null;
};

type RouterConfigProps = {
  handleVideoModal: () => void;
};

const RouterConfig = ({ handleVideoModal }: RouterConfigProps) => {
  return (
    <Routes>
      <Route path="/" element={<NewLandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/services-terms" element={<TermsOfServices />}></Route>
      <Route path="/download" element={<DownloadPage />}></Route>
      <Route path="*" element={<RedirectToPlayStore />} />
    </Routes>
  );
};

export default RouterConfig;
