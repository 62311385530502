import styles from "./NewLandingPage.module.scss";
import SellMobileImage from "assets/images/sellMobileImage.webp";
import ConnectVideoImage from "assets/images/connectVideoImage.png";
import VideoOverlay from "assets/videos/videoOverlay.png";
import AnimatedCard from "./AnimatedCard";
import CombinedImage from "assets/icons/combinedIcon.png";
// import Video from "assets/videos/video.mp4";
import useModal from "utils/hooks/useModal";
import SendLinkModal from "organisms/SendLinkModal/SendLinkModal";
import { useEffect, useRef } from "react";

const ConnectSection = () => {
  const { handleModal, openModal, openVideoModal, handleVideoModal } =
    useModal();

  const vidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (vidRef && vidRef.current) {
      vidRef.current.play();
    }
  }, []);

  return (
    <div className={styles.connectSection} id="connect">
      <div className={styles.connectContentSection}>
        <p className={styles.connectTitle}>
          Connect on <span>ByzWiz</span>
        </p>
        <p className={styles.connectDesc}>
          Connect with Followers & Customers on the ByzWiz Social Network.{" "}
        </p>
        <p className={styles.connectDesc}>
          Powerful social tools oriented towards economic activity.{" "}
        </p>
        <p className={styles.connectDesc}>
          Support & encourage the Home Entrepreneur community.{" "}
        </p>
        <button className={styles.btn} onClick={handleModal}>
          <p>Download the ByzWiz App</p>
          <img src={CombinedImage} className={styles.combinedImage} />
        </button>
      </div>

      <div className={styles.imageSection}>
        <AnimatedCard />
        <img src={SellMobileImage} className={styles.sellMobileImage} />
        <div className={styles.videoContainer}>
          {/* <img src={ConnectVideoImage} className={styles.video} /> */}
          <video
            className={styles.video}
            src={require("../../assets/videos/output.mp4")}
            // loop={true}
            // autoPlay={true}
            muted={true}
            ref={vidRef}
            poster={require("../../assets/videos/thumbnail.png")}
            // onEnded={() => {
            //   setTimeout(function () {
            //     if (vidRef && vidRef.current) {
            //       vidRef.current.play();
            //     }
            //   }, 500);
            // }}
            onEnded={() => {
              if (vidRef && vidRef.current) {
                vidRef.current.play();
              }
            }}
          />
          <img src={VideoOverlay} className={styles.overlay} />
        </div>
      </div>
      <SendLinkModal handleModal={handleModal} open={openModal} />
    </div>
  );
};
export default ConnectSection;
