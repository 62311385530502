import styles from "./NewLandingPage.module.scss";
import AndroidQr from "assets/images/footerAndroidQr.png";
import IosQr from "assets/images/appleStoreQr.png";
import { useNavigate } from "react-router-dom";
const FooterSection = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.footerSection}>
      <div className={styles.qrViewContainer}>
        <div className={styles.linkContainer}>
          <div className={styles.linkBox}>
            <p className={styles.title}>Main Pages</p>
            <a href="https://byb.byzwiz.com" target={"_blank"}>
              <p className={styles.link}>Start a Home Business</p>
            </a>
            <p className={styles.link}>License & Registrations</p>
            <a href="https://blog.byzwiz.com/" target={"_blank"}>
              <p className={styles.link}>Blog</p>
            </a>
          </div>
          <div className={styles.linkBox}>
            <p className={styles.title}>Legal</p>
            <p
              className={styles.link}
              onClick={() => navigate("/services-terms")}
            >
              Terms of Usage
            </p>
            <p
              className={styles.link}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </p>
            <p className={styles.link}>Home Business Policies</p>
          </div>
          <div className={styles.linkBox}>
            <p className={styles.title}>Support</p>
            <p className={styles.link}>FAQ&apos;s</p>
            <p className={styles.link}>Talk to Us</p>
            <p className={styles.link}>Tutorials</p>
          </div>
        </div>
        <div className={styles.qrContainer}>
          <p className={styles.title}>Scan QR to Download the App</p>
          <div className={styles.qrBox}>
            <img src={AndroidQr} className={styles.androidQr} />
            <img src={IosQr} className={styles.iosQr} />
          </div>
        </div>
      </div>
      <div className={styles.copyrightSection}>
        <p className={styles.tagLine}>
          India&apos;s First Home-to-Consumer Social Enterprise Platform
        </p>
        <p className={styles.copyright}>
          COPYRIGHT © BYZWIZ {new Date().getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default FooterSection;
